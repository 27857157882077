import React, { useEffect } from "react";
import { SectionContainer } from "../components/common/SectionContainer";

function Terms() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <SectionContainer>
      <div className=" mx-auto p-6">
        <h1 className="text-2xl font-bold text-center">User Agreement</h1>
        <p className="text-center font-bold py-2 ">SafeKidly Family</p>
        <p className="text-center font-bold">Version 2024 04 10</p>
        <h2 className="mt-8 text-xl font-bold">1. OVERVIEW AND ACCEPTANCE</h2>
        <p className="mt-4">
          We invite you to review these terms and conditions (referred to as
          "User Agreement") which govern your use of the SafeKidly Family
          platform (the "SafeKidly Platform") and the associated software (the
          "SafeKidly Software"), including our family monitoring services
          (collectively, the "SafeKidly Services") and one-on-one coaching (the
          "Family Coaching") provided by SafeKidly Technologies, S.L.
          ("SafeKidly," "We," or "Us"), located at Roger de Flor 193, 08013,
          Barcelona, Spain.
        </p>
        <p>
          By subscribing to the SafeKidly Services and accepting these terms,
          you ("You") acknowledge that you have read and agree to be bound by
          this User Agreement. If you do not agree, you must refrain from
          installing the SafeKidly Software or using the SafeKidly Services.
        </p>
        <p>
          You confirm that you have read and accepted the SafeKidly Family
          Privacy Policy, which is an integral part of this User Agreement.
        </p>
        <p>
          By utilizing SafeKidly Services, you agree to comply with these terms.
          Any violation of this agreement may result in termination of your
          SafeKidly Family account. You affirm that you are at least 18 years
          old and legally able to enter into this agreement.
        </p>
        <h2 className="mt-8 text-xl font-bold">2. QUSTODIO FAMILY SERVICES</h2>
        <h3 className="mt-4 text-lg font-bold">Service Description</h3>
        <p>
          The SafeKidly Services enable you to monitor and manage the use of
          your family’s devices (the "Monitored Devices") through a parental
          control panel, allowing you to customize monitoring and filtering
          settings via your SafeKidly Account, ensuring a safe browsing
          experience.
        </p>
        <p>
          These services aim to help you supervise the internet usage of
          children ("Users"), facilitating a balanced online environment that
          supports their ability to navigate the digital world responsibly.
        </p>
        <p>
          SafeKidly Services are intended solely for personal and family use,
          not for professional or educational purposes. If you utilize these
          services in any other context, you do so at your own risk, and only
          these terms will govern your use.
        </p>
        <p>
          The Family Coaching service, if chosen, connects you with
          professionals experienced in guiding families in their internet usage.
          SafeKidly merely facilitates this connection and is not responsible
          for the coaching services provided. More details can be found in Annex
          1.
        </p>
        <h3 className="mt-4 text-lg font-bold">Usage Guidelines</h3>
        <p>
          <strong>Permitted Use:</strong> You agree to use the SafeKidly
          Services in accordance with these terms and relevant laws, especially
          regarding privacy, personal rights, and child protection. SafeKidly
          disclaims responsibility for your use of its services, and you will
          not engage in any activity that may disrupt or damage the services or
          provide them to third parties.
        </p>
        <p>
          <strong>Legal Compliance:</strong> By using SafeKidly Services, you
          confirm that (i) you are not in a country subject to U.S. Government
          sanctions, and (ii) you are not on any prohibited party lists by the
          U.S. Government.
        </p>
        <p>
          <strong>Usage Restrictions:</strong> You must adhere to the following
          stipulations:
        </p>
        <ol className="list-decimal ml-6">
          <li>
            SafeKidly Services may only be used to monitor devices that you own
            or are legally entitled to monitor, in compliance with applicable
            laws.
          </li>
          <li>Usage is restricted to personal and domestic contexts only.</li>
          <li>
            You must inform Users about the monitoring and obtain their consent
            as required by law.
          </li>
          <li>
            Unauthorized uses of the SafeKidly application or your account
            credentials are strictly prohibited.
          </li>
          <li>
            It is forbidden to reverse engineer, violate security measures, or
            gain unauthorized access to Qustodio’s systems or data.
          </li>
        </ol>
        <p>
          You are solely responsible for ensuring the lawful use of SafeKidly
          Services and will indemnify SafeKidly for any unlawful actions.
        </p>
        <h3 className="mt-4 text-lg font-bold">Account Registration</h3>
        <p>
          To use SafeKidly Services, you must create an account at
          www.qustodio.com and provide accurate, up-to-date information. Keep
          your password confidential and notify us immediately of any
          unauthorized use of your account. Upon registration, you receive a
          limited, revocable license to use SafeKidly Services.
        </p>
        <h3 className="mt-4 text-lg font-bold">Account Duration</h3>
        <p>
          Your SafeKidly Account remains active unless canceled by you or us as
          described below:
        </p>
        <ul className="list-disc ml-6">
          <li>
            <strong>Free SafeKidly Account:</strong> Limited to 30 days from
            registration.
          </li>
          <li>
            <strong>Premium SafeKidly Account:</strong> Requires payment for the
            selected subscription period.
          </li>
        </ul>
        <h3 className="mt-4 text-lg font-bold">Account Cancellation</h3>
        <p>
          You can cancel your SafeKidly Account at any time by contacting{" "}
          <a
            href="mailto:info@prabhaktech.com"
            className="text-blue-500 inline ">
            info@prabhaktech.com
          </a>
          . We may terminate your account immediately if you breach these terms.
        </p>
        <h3 className="mt-4 text-lg font-bold">Service Termination</h3>
        <p>
          If your account is terminated for any reason, access to it will be
          revoked, and your data may be deleted, except as required for legal
          compliance or backup purposes.
        </p>
        <h3 className="mt-4 text-lg font-bold">Data Security</h3>
        <p>
          You acknowledge that data transmitted through SafeKidly Services is
          encrypted to ensure privacy and security.
        </p>
        <h3 className="mt-4 text-lg font-bold">SafeKidly Software</h3>
        <p>
          SafeKidly grants you a non-transferable license to install and use the
          SafeKidly Software on the devices covered by your subscription. You
          may not reproduce or distribute the software without permission.
        </p>
        <h3 className="mt-4 text-lg font-bold">Subscription Options</h3>
        <ul className="list-disc ml-6">
          <li>
            <strong>Free SafeKidly Account:</strong> Access to limited features
            for personal use.
          </li>
          <li>
            <strong>Premium SafeKidly Account:</strong> Enhanced functionality
            as specified in your subscription plan.
          </li>
        </ul>
        <h3 className="mt-4 text-lg font-bold">Fees and Charges</h3>
        <p>
          (Further details regarding fees and charges will be provided...)
        </p>{" "}
        <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>
        <section className="mb-6">
          <h2 className="font-bold">Fees</h2>
          <p className="text-base">
            The fees (“Fees”) for any plan are set forth at www.qustodio.com and
            on your Premium SafeKidly Account registration form. For payment
            plans of Your Premium SafeKidly Account, You agree to pay the Fees
            set forth in the plan selected by You. Payment is through the online
            payment system provided by Us or as agreed upon by Us. On periodic
            renewal, You will be charged at the then-current price, unless
            otherwise agreed in writing with Us. Payments are non-cancellable
            and non-refundable. If any Users or Monitored Devices are added
            during the subscription period in addition to the maximum
            contracted, they will result in an increase in the price of the Fees
            in accordance with the price levels of the subscriptions contracted
            at the beginning of that subscription period.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="font-bold">Price updates</h2>
          <p className="text-base">
            The prices for SafeKidly Services may change at any time during the
            subscription period by giving you thirty (30) calendar days or more
            written notice. If You do not object to such a price change in
            writing requesting termination of SafeKidly Services, You will be
            deemed to have accepted the new prices. New prices applicable to the
            next renewal or subscription period may be communicated to You prior
            to subscription renewal. Any continued use of SafeKidly Services
            after the renewal date will be deemed to have accepted the new
            prices.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="font-bold">Billing</h2>
          <p className="text-base">
            Invoices will be issued at the effective moment of payment by the
            users. You will be billed -at start-up and for additional users- for
            a full period, regardless of the start date. Renewals are invoiced
            as regularly as indicated on the order form. Unless otherwise stated
            in the order, invoices will be issued in euros or dollars (as
            indicated). You agree to provide us or our distributor with complete
            and accurate billing and contact information.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="font-bold">Delays in payments</h2>
          <p className="text-base">
            Late payments will bear interest at the rate of 1.5% per month or
            the maximum rate permitted by law (whichever is less). Interest will
            be generated from the date of such late payment until the date of
            payment. In the event of late payment, We may condition future
            renewals of subscriptions and order forms on payment terms shorter
            than those generally specified, or we may suspend or terminate this
            agreement and access to SafeKidly Services.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="font-bold">Taxes</h2>
          <p className="text-base">
            Unless otherwise stated, prices do not include applicable taxes,
            including, without limitation, VAT or equivalent sales tax
            applicable to you. The calculation and payment of such taxes is your
            responsibility. If we are legally obliged to pay or collect taxes
            for which you are responsible under this paragraph, the relevant
            amount will be invoiced and paid by you, unless you provide us with
            a valid tax exemption certificate authorized by the relevant tax
            authority.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="font-bold">Ownership</h2>
          <p className="text-base">
            SafeKidly is the owner or licensee of all intellectual and
            industrial property rights related to SafeKidly Services. The use of
            SafeKidly Services does not imply any assignment of all or part of
            such rights to you or your Users.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="font-bold">Registered trademarks</h2>
          <p className="text-base">
            You will not use our trademarks, commercial names, logos, domain
            names, other distinctive features of the marks, or any material
            subject to copyright or associated with SafeKidly Services without
            our consent. You will not remove any information or notices of
            authorship, ownership of rights or trademarks of SafeKidly.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="font-bold">User Contents</h2>
          <p className="text-base">
            In order to provide SafeKidly Services, the Platform monitors and
            stores information transmitted from the Monitored Devices where
            SafeKidly Software is installed, about local and online activities
            (use of applications, search, social networks, browsing, etc.) and
            communications (certain forms of social networks, instant messaging,
            etc.). This information is collected, stored and analyzed on your
            behalf and under the system configuration you perform, and you and
            the User involved are the sole owners of the content and are solely
            responsible for all information transmitted to us. You grant us a
            worldwide, irrevocable, royalty-free, non-exclusive, sub-licensable
            license to store and process such content on our servers for the
            provision of SafeKidly Services.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="font-bold">Warranties</h2>
          <p className="text-base">
            SafeKidly Services will be provided in a professional and diligent
            manner, in accordance with applicable regulations. Any service
            errors should be notified to the support service at{" "}
            <a
              href="mailto:info@prabhaktech.com"
              className="inline text-blue-500">
              info@prabhaktech.com
            </a>
            . Except for this express warranty, to the maximum extent permitted
            by applicable law, SafeKidly makes no other warranty, express or
            implied, regarding SafeKidly Services, and disclaims any warranty of
            fitness or satisfactory quality of the SafeKidly Services. If You
            downloaded the SafeKidly Service from Apple’s “App Store”, in the
            event the SafeKidly Service fails to conform to these warranties,
            you may notify Apple, and Apple will refund the Fees you paid. You
            are aware that Apple has no other warranty obligation with respect
            to the SafeKidly Service and all other claims, losses, liabilities,
            damages, costs or expenses attributable to any failure to conform
            with any warranty herein is Qustodio’s responsibility, in accordance
            with the provisions of these Terms of Service.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="font-bold">Claims</h2>
          <p className="text-base">
            SafeKidly is the sole responsible for addressing any claims from You
            or any third party relating to the SafeKidly Software, your
            possession and/or use of the SafeKidly Software. Furthermore,
            SafeKidly is solely responsible for the investigation, defense,
            settlement and discharge of any such intellectual property
            infringement claim.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="font-bold">Limitation of liability</h2>
          <p className="text-base">
            Except to the extent permitted by applicable law: SafeKidly will not
            be liable for (A) any indirect loss, including, but not limited to,
            lost profits, loss of business opportunities, goodwill or
            reputation, business interruption, computer failure or other
            damages; (B) losses arising out of or in connection with the
            following actions (i) unlawful and/or contrary uses to the
            provisions of the SafeKidly Terms of Service, and in particular, the
            restrictions on Use set forth in its Clause 2 (ii) the use or
            inability to use SafeKidly Services; (ii) unlawful or unauthorized
            access to User Data under the provisions of the Privacy Policy; (iv)
            the deletion or loss of data or messages transmitted through the
            SafeKidly Services or (v) any changes to SafeKidly Services notified
            to You; (vi) statements or conduct of any third party in providing
            SafeKidly Services. In any event, our liability will only be for
            direct damages and will in no event exceed the total amount paid by
            You under the SafeKidly Services in the twelve (12) months prior to
            the event causing the damage. This limitation will not apply in
            cases of wilful misconduct or damage directly caused to individuals
            or death.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="font-bold">Indemnification</h2>
          <p className="text-base">
            You agree to indemnify and hold us harmless in connection with any
            matter related to your misuse or improper use of the SafeKidly
            Services to the fullest extent permitted by law.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="font-bold">Modifications</h2>
          <p className="text-base">
            SafeKidly reserves the right to modify the design and/or
            configuration of the SafeKidly Services offered, without materially
            reducing the services offered. In addition, we may modify or update
            these Terms of Service (including the Privacy Policy). Any
            substantial modifications will be notified to you, through your
            registered email account and/or in the SafeKidly Services. If you do
            not accept the changes, you may terminate your subscription to
            SafeKidly Services. Your continued use of SafeKidly Services after
            any modifications will be deemed acceptance of the modified Terms of
            Service.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="font-bold">Annex 1 – Family Coaching</h2>
          <p className="text-base">
            Through the Family Coaching service, You can connect with
            professionals specialized in helping families with internet usage
            behavior (“Coaches”). Qustodio’s role through Family Coaching is
            limited to enabling you to connect with a Coach and facilitating
            payment. The Coaches are independent providers and are not
            Qustodio’s employees, representatives, or contractors. They will
            provide you with the one-to-one coaching sessions (“Sessions”) under
            their own terms of service that you are required to accept before
            scheduling the Session. We recommend you carefully read the Coaches’
            terms before accepting them.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="font-bold">Governing law and jurisdiction</h2>
          <p className="text-base">
            The SafeKidly Terms of Service are governed by the laws of Spain,
            and any disputes will be submitted to the competent courts in
            Madrid, Spain, provided that mandatory laws in your jurisdiction do
            not require another law to govern this contract or another
            jurisdiction.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="font-bold">Contact</h2>
          <p className="text-base">
            If you have any questions about these Terms of Service, please
            contact us at{" "}
            <a
              href="mailto:info@prabhaktech.com"
              className="text-blue-500 inline ">
              info@prabhaktech.com
            </a>
            .
          </p>
        </section>
        <section className="mb-6">
          <h2 className="font-bold">Annex 1 – Family Coaching</h2>
       
          <p className="text-base">
            Through the Family Coaching service, You can connect with
            professionals specialized in helping families with internet usage
            behavior (“Coaches”). Qustodio’s role through Family Coaching is
            limited to enabling you to connect with a Coach and facilitating
            payment.
          </p>
          <p className="text-base">
            The Coaches are independent providers and are not Qustodio’s
            employees, representatives, or contractors. They will provide you
            with the one-to-one coaching sessions (“Sessions”) under their own
            terms of service that you are required to accept before scheduling
            the Session. We recommend you carefully read the Coaches’ terms
            before accepting them.
          </p>
          <p className="text-base">
            After you select the Coach, accept his/her terms of service and pay
            the coaching fees, the Coach will get in touch with you. Be aware
            that these fees are charged periodically until you unsubscribe from
            the coaching service. If you are not satisfied with the Coach’s
            services, you can unsubscribe at any time, but SafeKidly will not
            intervene or otherwise be involved in any dispute or claim relating
            to the Coach’s independent services.
          </p>
          <p className="text-base">
            During the subscription period, you can change to a different Coach
            you can find on Family Coaching. If a Coach you connected with stops
            using the Family Coaching, we will notify you and you will have the
            opportunity to schedule Sessions with other Coaches if you wish.
          </p>
          <p className="text-base">
            You recognize that SafeKidly is not the provider of the Sessions,
            has no control over the services provided to You by the Coaches, and
            neither SafeKidly nor its directors, officers or personnel are
            involved in the provision of the coaching Sessions.
          </p>
          <p className="text-base">
            SafeKidly is not affiliated with any Coach. The way Coaches are
            shown in the Family Coaching is not intended to endorse any of them.
            The Family Coaching will show you available Coaches in random order
            or based on the criteria specifically chosen by You.
          </p>
          <p className="text-base">
            SafeKidly has no access to any data or information of the Sessions
            and does not endorse any specific test, medication, recommendations,
            products, or procedures that Coaches may recommend to you.
          </p>
          <p className="text-base">
            You choose a Coach and attend the Sessions at your own risk and
            agree to indemnify and hold us harmless in connection with any
            matter related to the services provided to you by the Coaches.
          </p>
        </section>
      </div>
    </SectionContainer>
  );
}

export default Terms;
