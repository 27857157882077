import React, { useEffect} from 'react'
import { SectionContainer } from '../components/common/SectionContainer'

function ProductPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <SectionContainer >
        <div className=" mx-auto p-6 bg-white rounded-lg">
            <h1 className="text-2xl font-bold mb-2">SAFEKIDLY FAMILY PRIVACY POLICY</h1>
            <h2 className="text-lg font-normal mb-4">Last Updated: 10 October, 2024</h2>

            <p className="mb-4">
                Safekidly Technologies SL (“safeKidly”, the “Company”, or “we/us”) is committed to respecting and protecting your privacy and that of your Users. This Privacy Policy explains our practices regarding the use of personal data collected and processed through our 
                <a href="#" className="text-blue-600 hover:underline  inline"> Safekidly Family Services</a> (the “Services” or “safeKidly Services”). This Privacy Policy integrates our conditions of use of our Services.
            </p>
            <p className="mb-4">
                In this Privacy Policy we will explain our practices regarding the use of personal data obtained and/or processed through our Safekidly Services.
            </p>
            <p className="mb-4">
                This Privacy Policy is part of our 
                <a href="#" className="text-blue-600 hover:underline  inline"> Safekidly Family Terms of Service</a>.
            </p>

            <h2 className="font-bold mt-6 mb-2">Summary</h2>
            <p className="mb-4">
                Safekidly processes two types of personal data in relation to the Services: 
                <ol className="list-decimal ml-6 mb-4">
                    <li>Your account and contact data, for which we are responsible (as Data Controller, as defined under the EU’s General Data Protection Regulation (GDPR), or as a business, as defined under the California Consumer Privacy Act (CCPA));</li>
                    <li>Your User Data, collected from your monitored devices associated with your User Accounts (“Monitored Devices”), for which you are responsible (as Data Controller as defined under GDPR), while Safekidly acts as data processor as defined under GDPR or as a service provider as defined under CCPA.</li>
                </ol>
            </p>
            <ul className="list-disc ml-6 mb-4">
                <li>Your account and contact data are used for managing our relationship with you, including activation, support, invoicing, and upselling. This is described in Section A of the policy.</li>
                <li>Your User and Monitored Devices information is processed on your behalf, for providing the parental control and monitoring services through the control panel. This is described in Section B of the policy.</li>
            </ul>

            <p className="mb-4">
                You are solely responsible for the configuration and use of the parental control panel and the processing of personal data associated with your account, which includes, among others, collecting, storing, and analyzing personal data from Users’ Monitored Devices. The Safekidly Services automatically deploys the configuration and instructions given by you, and you are solely responsible for the configuration of this control panel. You can change those configurations and provide instructions to limit and/or erase any data collected within the control panel.
            </p>
            <strong>If you use the Safekidly Service to see data about your User’s usage of school devices collected by Linewize Inc. through its service School Manager, please read section C of this Privacy Policy.</strong>

            <h2 className="font-bold mt-6 mb-2">We Comply With The Children’s Online Privacy Protection Act of 1998 (COPPA)</h2>
            <p className="mb-4">
                COPPA and its rules require us to inform parents and legal guardians (“parents”) about our practices for collecting, using, and disclosing personal information from children under the age of 13 (“children”). It also requires us to obtain verifiable consent from a child’s parent or confirm that the child’s teacher has obtained verifiable consent from such child’s parent, for certain collection, use, and disclosure of the child’s personal information.
            </p>
            <p className="mb-4">
                Read more about COPPA at the FTC’s COPPA page. This regulation is designed to protect the privacy of your children. In order for a child under the age of 13 located in the United States to use the Safekidly Services, his/her parent or teacher must approve the registration.
            </p>
            <p className="mb-4">
                The Safekidly Services is designed for use in a household environment by parents and not by children, and we do not knowingly collect personal information directly from children under the age of 13. You warrant that (a) you have informed any Users that are 14 years old or more that the Monitored Devices used by them include control and monitoring software and have obtained authorization from such Users for this kind of activity or (b) there are rules in your country that exempt you from the aforementioned obligation of information and consent, or provides for a different age limit, in which case that age limit will apply. Both Safekidly and you agree to fully comply with this Privacy Policy.
            </p>

            <h2 className="font-bold mt-6 mb-2">A. DATA PROCESSED BY SAFEKIDLY AS DATA CONTROLLER</h2>
            <p className="mb-4">
                Please note that this Section does NOT regulate the processing of user data from the Monitored Devices (“User Data”) by Safekidly in its capacity as Processor, which is regulated by Section B attached.
            </p>
            <ol className="list-decimal ml-6 mb-4">
                <li className="font-bold">Data Controller</li>
                <p className="ml-4">The Data Controller is Safekidly Technologies 1st floor here showroom building, Raithupeta, Nandigama, NTR District, Andhra Pradesh India. You can contact our Data Protection Officer to send any suggestions, queries, doubts or complaints about personal data, or to access your personal data by writing to: <a href="mailto:info@prabhaktech.com" className="text-blue-600 hover:underline inline">info@prabhaktech.com</a>.</p>

                <li className="font-bold">Data collection by the Company through the Services</li>
                <p className="ml-4">
                    <strong>Data Collection:</strong> Safekidly will collect and process as data controller the following personal data through the Services:
                </p>
                <ul className="list-disc ml-6 mb-4">
                    <li>
                        <strong>Registration Data:</strong> On registering for Services, we will collect the following personal data about you: name, surname, email address, and telephone. This data is mandatory and if it is not provided, your account cannot be created.
                    </li>
                    <li>
                        <strong>Payments:</strong> Our payment providers (PayPal, Braintree, Cleverbridge, Chargebee, and Stripe) collect certain payment data which is processed according to their terms and privacy policy which is provided to you during the payment process. You can visit 
                        <a href="https://www.braintreepayments.com/en-es/legal" className="text-blue-600 hover:underline inline"> Braintree</a>, 
                        <a href="https://www.cleverbridge.com/corporate/privacy-policy/" className="text-blue-600 hover:underline inline"> Cleverbridge</a>, 
                        <a href="https://www.chargebee.com/privacy/" className="text-blue-600 hover:underline  inline"> Chargebee</a>, and 
                        <a href="https://stripe.com/it/privacy-center/legal" className="text-blue-600 hover:underline  inline"> Stripe</a> 
                        for further information. We may contract additional payment gateways, and their conditions will be provided to you during the payment process.
                    </li>
                    <li>
                        <strong>Information about your computer:</strong> Due to the communications standards on the internet, when you visit our platform we automatically receive the URL of the site from which you came and the site to which you are going when you leave the site. We also receive the internet protocol (“IP”) address of your computer and the type of web browser you are using. We use this information to analyze overall trends and to help improve the Services. This information is not shared with third parties without your permission.
                    </li>
                </ul>
                <p className="ml-4">It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>

                <li className="font-bold">Information We Collect</li>
                <p className="ml-4">To comply with the CCPA, a business must describe the categories of personal information it has collected about consumers during the past twelve (12) months. Accordingly, this section applies to visitors, users, and others accessing the Safekidly Services who reside in the State of California.</p>
                <p className="ml-4">We collect information that identifies, relates to, describes, references, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device, including:</p>
                <ul className="list-disc ml-6 mb-4">
                    <li><strong>Identifiers:</strong> such as a name, email address, postal address, unique personal identifier, online identifier, Internet Protocol address, account name, or other similar identifiers.</li>
                    <li><strong>Personal Information Categories:</strong> as listed in the California Civil Code Section 1798.80(e).</li>
                </ul>

                <li className="font-bold">Legal Basis for Processing</li>
                <p className="ml-4">In order to process personal data, we rely on one of the following legal grounds, where applicable:</p>
                <ul className="list-disc ml-6 mb-4">
                    <li>Legitimate interests;</li>
                    <li>Consent;</li>
                    <li>Compliance with legal obligations.</li>
                </ul>

                <li className="font-bold">Purpose of Processing</li>
                <p className="ml-4">We will use your personal data for the following purposes:</p>
                <ul className="list-disc ml-6 mb-4">
                    <li>To manage our relationship with you and provide you with customer support;</li>
                    <li>To process transactions and send you related information, including purchase confirmations and invoices;</li>
                    <li>To communicate with you, including sending you updates and information about your subscription;</li>
                    <li>To send you marketing communications, where you have opted in to receive these communications;</li>
                    <li>To improve our Services, including conducting research and analysis.</li>
                </ul>

                <li className="font-bold">Data Retention</li>
                <p className="ml-4">We retain your personal data only for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
            </ol>

            <h2 className="font-bold mt-6 mb-2">B. DATA PROCESSED BY SAFEKIDLY AS DATA PROCESSOR</h2>
            <ol className="list-decimal ml-6 mb-4">
                <li className="font-bold">General Description of the Data Processed</li>
                <p className="ml-4">When you register with Safekidly and create a User Account, you can link it to monitored devices (“Monitored Devices”), where you will be able to view information and reports from each User’s activity on those devices.</p>
                <p className="ml-4">The information that we collect from each of the Monitored Devices will include:</p>
                <ul className="list-disc ml-6 mb-4">
                    <li>Applications Installed;</li>
                    <li>Websites Visited;</li>
                    <li>Device Usage;</li>
                    <li>Call and SMS History;</li>
                    <li>Location Information.</li>
                </ul>
                <p className="ml-4">Safekidly processes this information solely on your behalf and according to your instructions in the configuration settings of your account. You can modify the instructions or configurations at any time through the parental control panel.</p>

                <li className="font-bold">Your Responsibilities</li>
                <p className="ml-4">You represent and warrant that you have obtained all necessary consents from Users or third parties for the processing of User Data as contemplated under this Privacy Policy and to fulfill your obligations in relation to the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA).</p>

                <li className="font-bold">User Data Security</li>
                <p className="ml-4">Safekidly is committed to ensuring that your User Data is secure. We have put in place appropriate technical and organizational measures to prevent unauthorized access, disclosure, alteration, or destruction of your User Data.</p>

                <li className="font-bold">User Data Retention</li>
                <p className="ml-4">We will retain User Data only as long as necessary to provide you with the Services and to comply with our legal obligations.</p>
            </ol>

            <h2 className="font-bold mt-6 mb-2">C. LINEWIZE SCHOOL MANAGER</h2>
            <p className="mb-4">If you are using the Safekidly Service to see data about your User’s usage of school devices collected by Linewize Inc. through its service School Manager, please be informed that the personal data collected by Linewize is subject to Linewize’s privacy policy.</p>
            {/* <p className="mb-4">For more information on Linewize’s privacy policy, please refer to <a href="https://www.linewize.com/privacy-policy" className="text-blue-600 hover:underline inline ">Linewize Privacy Policy</a>.</p> */}

            <h2 className="font-bold mt-6 mb-2">D. YOUR RIGHTS</h2>
            <p className="mb-4">Under applicable data protection law, you have the following rights:</p>
            <ul className="list-disc ml-6 mb-4">
                <li>The right to access your personal data.</li>
                <li>The right to rectify inaccurate personal data.</li>
                <li>The right to erasure of your personal data.</li>
                <li>The right to restrict the processing of your personal data.</li>
                <li>The right to data portability.</li>
                <li>The right to object to the processing of your personal data.</li>
            </ul>
            <p className="mb-4">To exercise these rights, please contact us at <a href="mailto:info@prabhaktech.com" className="text-blue-600 hover:underline inline ">info@prabhaktech.com</a>.</p>

            <h2 className="font-bold mt-6 mb-2">E. CHANGES TO THIS PRIVACY POLICY</h2>
            <p className="mb-4">We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

            <h2 className="font-bold mt-6 mb-2">F. CONTACT US</h2>
            <p className="mb-4">If you have any questions about this Privacy Policy, please contact us:</p>
            <p className="ml-4">By email: <a href="mailto:info@prabhaktech.com" className="text-blue-600 hover:underline inline ">info@prabhaktech.com</a></p>
            <p className="ml-4">By mail: Safekidly Technologies 1st floor here showroom building, Raithupeta, Nandigama, NTR District, Andhra Pradesh, India</p>
        
            <ul className="list-disc list-inside space-y-2">
        <li>
          <strong>Amazon Web Services</strong> (Amazon.com, Inc.) provides us with cloud hosting services outside the India. For more information, visit <a href="https://aws.amazon.com/privacy/" className="text-blue-600 hover:underline inline ">Amazon’s Privacy Policy</a>.
        </li>
        <li>
          <strong>Braze, Inc.</strong> allows us to improve the experience of our users. For more information, visit <a href="https://www.braze.com/privacy" className="text-blue-600 hover:underline inline ">Braze’s Privacy Policy</a>.
        </li>
        <li>
          <strong>Chargebee, Inc.</strong> provides us with billing and payment services. More information is available on <a href="https://www.chargebee.com/privacy/" className="text-blue-600 hover:underline inline ">Chargebee’s Privacy Policy</a>.
        </li>
        <li>
          <strong>Chart.io</strong> allows us to combine data from different systems and databases to perform data analysis. For more information, visit <a href="https://chartio.com/about/legal/privacy/" className="text-blue-600 hover:underline inline ">their Privacy Policy</a>.
        </li>
        <li>
          <strong>Cleverbridge, Inc.</strong> provides us with billing services. For more information, visit <a href="https://www.cleverbridge.com/corporate/privacy-policy/" className="text-blue-600 hover:underline inline ">Cleverbridge’s Privacy Policy</a>.
        </li>
        <li>
          <strong>Mailchimp</strong> (The Rocket Science Group LLC d/b/a Mailchimp) is our mail marketing service provider. For more information, visit <a href="https://mailchimp.com/es/help/about-the-general-data-protection-regulation/" className="text-blue-600 hover:underline inline ">Mailchimp’s Privacy Policy</a>.
        </li>
        <li>
          <strong>PayPal, Inc.</strong> provides us the billing service “Braintree”. You can find more information <a href="https://www.braintreepayments.com/en-es/legal" className="text-blue-600 hover:underline inline ">here</a>.
        </li>
        <li>
          <strong>Segment.io, Inc.</strong> (Twilio, Inc. d/b/a Segment.io, Inc.) provides us with a Customer Data Platform to manage our customers’ data. For more information, visit <a href="https://segment.com/legal/privacy/" className="text-blue-600 hover:underline inline ">Segment’s Privacy Policy</a>.
        </li>
        <li>
          <strong>Stripe, Inc.</strong> provides us with payment services. For more information, visit <a href="https://stripe.com/it/privacy-center/legal" className="text-blue-600 hover:underline inline ">Stripe’s Privacy Policy</a>.
        </li>
        <li>
          <strong>Zendesk Inc.</strong> provides support services outside the India. For more information, visit <a href="https://www.zendesk.com/company/agreements-and-terms/privacy-notice/" className="text-blue-600 hover:underline inline">Zendesk’s Privacy Policy</a>.
        </li>
      </ul>

      <p className="mt-4">
        For more information about our service providers that carry out international data transfers, please contact <a href="mailto:info@prabhaktech.com" className="text-blue-600 hover:underline inline ">info@prabhaktech.com</a>.
      </p>

      <h2 className="text-xl font-semibold mt-6">Data Security</h2>
      <p className="mb-4">
        We have adopted technical and organizational measures to preserve and protect your personal information from unauthorized use or access and from being altered, lost or misused. In case of a security breach, we will notify you electronically in a timely manner.
      </p>

      <h2 className="text-xl font-semibold mt-6">Data Subject’s Rights</h2>
      <ul className="list-disc list-inside space-y-2">
        <li><strong>Request access:</strong> to your personal data (data subject access request).</li>
        <li><strong>Request correction:</strong> of the personal data that we hold about you.</li>
        <li><strong>Request erasure:</strong> of your personal data.</li>
        <li><strong>Object to processing:</strong> of your personal data where we are relying on a legitimate interest.</li>
        <li><strong>Request restriction of processing:</strong> of your personal data.</li>
        <li><strong>Request the transfer:</strong> of your personal data to you or to a third party.</li>
        <li><strong>Withdraw consent:</strong> at any time where we are relying on consent to process your personal data.</li>
        <li><strong>File a complaint:</strong> to the supervisory authority.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6">Exercising Your Rights</h2>
      <p>
        To exercise your rights, please contact us at 
        <a href="mailto:info@prabhaktech.com" className="text-blue-600 hover:underline inline "> info@prabhaktech.com</a> 
        or send a letter to Safekidly Technologies 1st floor here showroom building, Raithupeta, Nandigama, NTR District, Andhra Pradesh India.
      </p>
      <p>
        If you contact us to exercise your rights, we may need to request specific information from you to confirm your identity and ensure your right to access your personal data. This is a security measure to protect your information. We may also contact you for further information to expedite our response.
      </p>

      <h2 className="text-xl font-semibold mt-6">Exercising Your Rights to Know or Delete</h2>
      <p>
        To exercise your rights to know or delete, please submit a request by either:
      </p>
      <ul className="list-disc list-inside space-y-2">
        <li>Emailing us at <a href="mailto:info@prabhaktech.com" className="text-blue-600 hover:underline inline ">info@prabhaktech.com</a></li>
        <li>Submitting a support request within the Safekidly Services.</li>
      </ul>
      <p>
        Only you, or someone legally authorized to act on your behalf, may make a request to know or delete related to your personal information. To designate an authorized agent, please submit a request by emailing us at <a href="mailto:info@prabhaktech.com" className="text-blue-600 hover:underline inline ">info@prabhaktech.com</a>.
      </p>
      <p>
        You may also make a request to know or delete on behalf of your child by emailing us at <a href="mailto:info@prabhaktech.com" className="text-blue-600 hover:underline inline ">info@prabhaktech.com</a>.
      </p>
      <p>You may only submit a request to know twice within a 12-month period. Your request to know or delete must:</p>
      <ul className="list-disc list-inside space-y-2">
        <li>
          Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative, which may include, but is not limited to:
          <ul className="list-decimal list-inside ml-6 space-y-1">
            <li>(a) Contact information associated with the account.</li>
            <li>(b) The user profile name.</li>
            <li>(c) The name of one or more Monitored Devices.</li>
            <li>(d) Technical information (e.g., model ID, serial number, IMEI code, operating system, etc.).</li>
            <li>(e) Any other piece of personal information we determine is sufficient for verifying your identity.</li>
          </ul>
        </li>
        <li>
          Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.
        </li>
      </ul>
      <p>
        We cannot respond to your request or provide personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.
      </p>
      <p>
        You do not need to create an account with us to submit a request to know or delete. However, we consider requests made through your password-protected account sufficiently verified when the request relates to personal information associated with that specific account.
      </p>
      <p>
        We will only use personal information provided in the request to verify the requestor’s identity or authority.
      </p>

      <h2 className="text-xl font-semibold mt-6">Response Timing and Format</h2>
      <p>
        We endeavor to respond to a verifiable consumer request within forty-five (45) days of receipt. If we require more time (up to another 45 days), we will inform you of the reason and extension period in writing.
      </p>
      <p>
        If you have an account with us, we will deliver our written response to that account. If you do not have an account, we will deliver our response by mail or electronically, at your option.
      </p>
      <p>
        Any disclosures will cover the 12-month period preceding our receipt of your request. The response will explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format that allows you to transmit your personal information easily.
      </p>
      <p>
        We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine a fee is warranted, we will inform you of the reason and provide a cost estimate before completing your request.
      </p>

      <h2 className="text-xl font-semibold mt-6">Non-Discrimination</h2>
      <p>
        We will not discriminate against you for exercising any of your rights.
      </p>

      <h2 className="text-xl font-semibold mt-6">Non-Discrimination</h2>
      <p>
        We will not discriminate against you for exercising any of the rights described above, and we will not:
      </p>
      <ul className="list-disc list-inside space-y-2">
        <li>Deny you goods or services.</li>
        <li>
          Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.
        </li>
        <li>Provide you a different level or quality of goods or services.</li>
        <li>
          Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.
        </li>
      </ul>

      <h2 className="text-xl font-semibold mt-6">Commercial Communications</h2>
      <p>
        As a user of safeKidly’s Services, you will receive electronic commercial communications in accordance with applicable law, including alerts, notices, newsletters, offers, and promotions related to safeKidly’s Services. If you do not wish to receive such information, you can opt out by clicking “unsubscribe” in one of our emails or by sending a notification to 
        <a href="mailto:info@prabhaktech.com" className="text-blue-600 hover:underline inline "> info@prabhaktech.com</a>.
      </p>

      <h2 className="text-xl font-semibold mt-6">General</h2>
      <p>
        We may amend this Privacy Policy as required to adapt it to future legislative or case law developments. We will notify you by posting a clear notice of these changes on our website, platform, and in this Privacy Policy.
      </p>
      <p>
        Unless a specific local regulation provides otherwise, the Privacy Policy is governed by the laws of India.
      </p>

      <h2 className="text-xl font-semibold mt-6">Safekidly Processing User Data as Data Processor/Service Provider</h2>
      <p>
        When registering and creating an Account, Safekidly starts collecting data from the Devices associated with the Account, which may include personal data relating to you, Users of the Devices, or third parties (“<b>User Data</b>”, including information about your Devices, websites and apps that your Users use, contacts, connections, payments, messages and other communications, posted and received content, etc.). Furthermore, in the event of any promotional or other campaign, Safekidly may process the contact information (name and email address) of a potential new client or user of our Services you provide us with.
      </p>
      <p>
        We, as data processor under the GDPR and service provider under the CCPA, process User Data under instruction from you (our customer, the “Client”), acting as data controller. This means that you are in control of this data: you determine which data is collected and how it is used for your parental control purposes. 
      </p>

      <h2 className="text-xl font-semibold mt-6">1. Object and Term</h2>
      <p>
        The purpose of this section is to regulate the processing of the User Data indicated in Appendix 1, that you make available to Safekidly for us to provide you with the Safekidly Service. The term of validity of this section is established by virtue of your subscription with Safekidly.
      </p>

      <h2 className="text-xl font-semibold mt-6">2. Warranty and Indemnity</h2>
      <p>
        You, as the person responsible for User Data that we process on your behalf, represent and warrant to us that you have all the appropriate informed consents or other legal basis for processing from each and every data subject whose personal data are submitted to us in the course of the provision of the Services. You agree to indemnify and keep us harmless from all claims, damages, and losses we may suffer relating to or arising out of the processing of User Data and other third-party personal data submitted to our systems during the course of use and provision of the Services.
      </p>

      <h2 className="text-xl font-semibold mt-6">3. Your Use of User Data</h2>
      <p>
        As Data Controller, you warrant that you have the appropriate authority to collect and process the User Data and any other data you provide us with. You will not submit to the Services any personal data relating to any individual over 13 that has not authorized such processing. Through the Services, you may also access a copy of the User Data collected by us on your behalf. You will protect the confidentiality of any accessible User Data and prevent access by or disclosure to any unauthorized third person.
      </p>

      <h2 className="text-xl font-semibold mt-6">4. Service Configuration and Data Processing Instructions</h2>
      <p>
        The Client is responsible for setting the parental control panel configurations on the Safekidly Platform that control the Services, the supervision and monitoring of activities, and determines the personal data to be covered. The installation of the Safekidly Device Software and your configuration of the control panel constitute instructions for us to process User Data on your behalf. The level and degree of such surveillance and monitoring are entirely under your control, and we will not be liable for any such configuration and control carried out by you. Furthermore, your support requests via email or phone also constitute instructions for us to process User Data, as long as processing User Data is necessary to help you with your request.
      </p>

      <h2 className="text-xl font-semibold mt-6">5. Retention</h2>
      <p>
        We store the User Data until you close your Account. After that period, we disassociate the personal data from the individual it refers to and use such disassociated data for internal research and analysis purposes.
      </p>

      <h2 className="text-xl font-semibold mt-6">6. Data Removal</h2>
      <p>
        During your subscription, we generally retain your User Data on an identifiable basis for 12-month periods, for providing our annual behavior report. Through the Platform control panel, you may delete all historical data saved at any time. This data will no longer be accessible and will be fully removed from our systems on the next back-up. If you wish to remove all the User Data in your Safekidly Account, please uninstall Safekidly from your devices and send an email, with a digital copy of your ID or other identification document to prove your identity, requiring the deletion of all the data we have about you and your Users. Once your identity is confirmed, we will immediately remove all Data from our active systems and back-ups within fifteen (15) days from confirmation of identity.
      </p>

      <h2 className="text-xl font-semibold mt-6">7. Rights and Responsibilities of Safekidly as Data Processor</h2>

      <p className="font-normal mb-2">
                As established in the applicable laws and regulations, Safekidly shall:
            </p>
            <div className="pl-10">
                <p className="text-lg">
                    a) Process User Data only on the basis of documented instructions from you, including transfers of User Data to a third country or international organization, unless otherwise required to do so under Union law or applicable Member State law. In such case, Safekidly will inform you of that legal requirement prior to the processing, unless otherwise prohibited by such law or in the public interest.
                </p>
                <p className="text-lg">
                    b) Ensure that the persons authorised to process User Data have undertaken to respect confidentiality or are subject to an obligation of confidentiality of a statutory nature.
                </p>
                <p className="text-lg">
                    c) Take all appropriate technical and organisational measures to ensure a level of safety appropriate to the risk of processing.
                </p>
                <p className="text-lg">
                    d) Respect the conditions for having recourse to another data processor, as established in the current legislation on protection of personal data.
                </p>
                <p className="text-lg">
                    e) Assist the data controller taking into account the nature of the processing, through appropriate technical and organisational measures, whenever possible, so that it can comply with its obligation to respond to requests for the exercise of the rights of the data subjects, in this case, the Users.
                </p>
                <p className="text-lg">
                    f) Assist you in ensuring that you comply with your privacy obligations, if any, taking into account the nature of the processing and the information that is available to Safekidly.
                </p>
                <p className="text-lg">
                    g) At your choice, either destroy or return all personal data once the processing services have been completed and destroy any existing copies unless the retention of personal data is required under Union or applicable Member State law.
                </p>
                <p className="text-lg">
                    h) Make available to you all information necessary to demonstrate compliance with the obligations established herein.
                </p>
                <p className="text-lg">
                    i) Process the User Data placed at the disposal of Safekidly in a way that ensures that the personnel in charge follows Your instructions.
                </p>
                <p className="text-lg">
                    j) Ensure that the DPO is involved in an adequate and timely manner in all matters relating to the protection of User Data.
                </p>
                <p className="text-lg">
                    k) Adhere to a Code of Conduct that is approved by the Indian Commission or other competent authority, if applicable.
                </p>
                <p className="text-lg">
                    l) Keep a record of processing activities in the case of processing personal data that may pose a risk to the rights and freedoms of the data subject and/or in a non-occasional manner, or which involves the processing of special categories of data and/or data relating to convictions and infractions.
                </p>
            </div>
            <ol className="list-decimal list-inside mt-4">
                <li className="font-bold">Data Subjects’ Exercise of their Rights.</li>
                <p className="font-normal">
                    If the Data Subjects (Users) address a request or exercise any of the rights established in the General Data Protection Regulation, you and/or Safekidly must provide the information requested and perform any required actions, without delay and, at the latest, within one month from receiving the request, which may be extended for a further two months if necessary, taking into account the complexity of the application and the number of applications.
                </p>
                <p className="font-normal">
                    Similarly, in the event that you and/or Safekidly do not proceed with the request of the User, he/she/they shall inform the latter without delay, and no later than one month after receipt of the request, shall provide the User with the reasons why he/she/they has/have not acted and inform the User of his/her/their right to file a complaint before a competent authority and to file a judicial appeal. The response to the User’s request shall be made in the same format as that used by the person concerned, unless he/she/they requests that it be done otherwise.
                </p>
            </ol>
            <ol className="list-decimal list-inside mt-4">
                <li className="font-bold">Subcontracting.</li>
                <p className="font-normal">
                    Safekidly may subcontract its obligations and/or give access to User Data to third-party service providers, if it is necessary for the proper provision of the Services. For this purpose, you hereby expressly authorise Safekidly to subcontract the entities indicated in Appendix 1. Safekidly ensures a contract exists with each third-party subcontractor, which is sufficient to require the subcontractor to process User Data in accordance with the applicable data protection laws and the Client’s instructions.
                </p>
            </ol>
            <ol className="list-decimal list-inside mt-4">
                <li className="font-bold">International Transfer of Data.</li>
                <p className="font-normal">
                    International transfers of User Data about users located in the EU may only be performed if the requirements of national and/or Indian laws and regulations that regulate them are met. User Data about users located outside the EU may be transferred to our servers located in India. Safekidly uses third-party technological services for the provision of Services, and these entities may be in jurisdictions that generally do not provide adequate safeguards in relation to the processing of personal data.
                </p>
                <p className="font-normal">
                    For all entities outside the Economic Indian Area, we have entered contracts with such entities that do include such safeguards, including the EC model clauses (see Section A.3 above and Appendix 1 below for a list of these entities). For more information, please contact <a href="mailto:info@prabhaktech.com" className="text-blue-600 inline ">info@prabhaktech.com</a>.
                </p>
            </ol>
            <ol className="list-decimal list-inside mt-4">
                <li className="font-bold">Security Breach of the Personal Data.</li>
                <p className="font-normal">
                    Insofar as there exists an instruction from a competent supervisory authority, a development of a national legislation or a delegated act, in the event of a security breach of the personal data, Safekidly shall notify you and the competent supervisory authority of such breach without undue delay, and if possible, no later than seventy-two (72) hours after it happened.
                </p>
            </ol>
            <ol className="list-decimal list-inside mt-4">
                <li className="font-bold">Termination, Resolution &amp; Expiration.</li>
                <p className="font-normal">
                    In the event of termination, resolution or expiration of the contractual relationship for the provision of services hereunder between you and Safekidly, the latter shall not keep the User Data unless otherwise legally required or advisable to do so. Otherwise, upon termination, resolution or expiration, or when no longer legally required to keep the data, Safekidly shall destroy or return to the Client all personal data and any copies of it, as well as any support or other document containing any personal data. This is without prejudice to the right of Safekidly to continue to process User Data where such data is being processed by Safekidly or for the defense of its legal interests.
                </p>
            </ol>
            <ol className="list-decimal list-inside mt-4">
                <li className="font-bold">Changes to Our Privacy Policy</li>
                <p className="font-normal">
                    We reserve the right to amend this privacy policy at our discretion and at any time. When we make changes to this privacy policy, we will post the updated notice on the Application and update the notice’s effective date. <b>Your continued use of the Safekidly Services following the posting of changes constitutes your acceptance of such changes.</b>
                </p>
            </ol>
            <table className="mt-6 w-full border-collapse border border-gray-300">
                <tbody>
                    <tr>
                        <td colSpan="2" className="border border-gray-300 p-4">
                            <b>Appendix 1</b>
                            <p><b>Details of Processing</b></p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" className="border border-gray-300 p-4">
                            <b>For CCPA compliance purposes, Safekidly has collected the following types of personal data/information from the consumers described below within the past twelve months. Under the GDPR, the data indicated below are data that Safekidly processes as data processor.</b>
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray-300 p-4"><b>Categories of Data Subjects/consumers</b></td>
                        <td className="border border-gray-300 p-4">Users of the devices which are monitored by Safekidly. Third parties who interact with the users of such devices.</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-300 p-4"><b>Type of personal data/personal information</b></td>
                        <td className="border border-gray-300 p-4">
                            <p>a) Identifiers such as real name, alias or account name, unique personal identifier, online identifier, IP address, email address.</p>
                            <p>(b) Information about the Monitored Devices usage, depending on the functionalities configured by the Account Owner in the dashboard, such as the URL of the visited websites on the supported web browsers, each website usage time and number of visits, each application usage time, the Monitored Devices usage times, and the Monitored Device location information. All data collected by such devices, including identification and contact data, Internet browsing and content viewing data, behavioural data.</p>
                            <p>(c) Technical information about the Monitored Device, such as the model ID. For iOS devices, after installing the MDM profile, Safekidly will have access to all the traffic of the device. This information (coming from the device) only goes to Safekidly servers and is not shared with any third party. The specific information collected through MDM is:</p>
                            <ul className="list-disc list-inside">
                                <li>Domain names, user agent and operating system version; in order to categorize websites and applications visited by the device, and thus make the filtering established by the parent. It is also used to report activity to the parent.</li>
                                <li>The URL address in the web search engines; to report the search to the parents.</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className="mt-6 font-bold">List of third parties accessing the User Data</p>
            <ul className="list-disc list-inside">
                <li><b>Amazon Inc.</b> provides Safekidly the service of data hosting outside the India.</li>
                <li><b>ZenDesk Inc.</b> provides Safekidly with support services outside the India.</li>
                <li>The third parties indicated in section A.3 for the provision of their services.</li>
            </ul>
            <p className="mt-6 font-bold">C. DATA COLLECTED THROUGH SCHOOL MANAGER AND SHOWN VIA THE SAFEKIDLY SERVICE</p>
            <p>
                If your child’s school subscribed to Linewize Inc’s service “School Manager”, you may be able to see data about your child’s usage of the school’s devices (“School Manager Data”) on the Safekidly Service.
            </p>
            <p>
                The data controller of the School Manager Data is the school that subscribed to the service, please contact the school for more information on the school’s privacy practices.
            </p>
            <p>
                Safekidly processes the School Manager Data as a data subprocessor for Linewize, solely for the purpose of presenting those data to you via our user interface, under the instructions of the school. We treat all data we process with utmost confidentiality under the same conditions as indicated above for our Safekidly Service. We inform you that in order to present the School Manager Data via our user interface, we use certain IT service suppliers indicated above who process the data for us (support, data management and analysis and user experience management).
            </p>
            <p>
                We will store the School Manager Data until the school, as Data Controller, requests us to delete them. You and your child have the right to exercise your data subject rights as indicated in section A.5; should we receive any request on how Linewize or the school process personal data, we will forward such request to them.
            </p>
            <p>
                Should you have any questions on how School Manager processes your child’s data, please contact <a href="mailto:info@prabhaktech.com" className="text-blue-600 inline">info@prabhaktech.com</a>.
            </p>
        </div>
    </SectionContainer>
  )
}

export default ProductPolicy



























































































