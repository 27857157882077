import React, {  useEffect} from 'react'
import { SectionContainer } from '../components/common/SectionContainer'
import { GoDotFill } from "react-icons/go";


function CookiePolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <SectionContainer >
        <div className="p-4 my-6 md:my-10">
        <h1 dir="ltr" className="text-3xl font-bold text-center text-gray-800 mb-4">
            COOKIES POLICY
        </h1>
        <p dir="ltr" className="text-gray-700 text-base mb-4">
            Safekidly Technologies S.L.U. is the owner of the website 
            <a href="https://mdm.prabhaktech.com" className="text-blue-500 hover:underline inline mx-1 "> 
            https://www.prabhaktech.com
            </a> 
            (hereinafter the “website”) and the Safekidly Parental Control app and platform (hereinafter the “app”) and uses cookies that collect information related to the connection, browsers and devices used by users who access and use the website and app (hereinafter the “users”) to manage and improve the proper functioning of the website, as further explained below.
        </p>
        <p dir="ltr" className="text-gray-700 text-base mb-4">
            This policy applies to both the website and the app, and describes what information the cookies and similar technologies (hereinafter, collectively, “cookies”) collect, how they are used and for what purpose. It also indicates how the user can restrict or block the automatic downloading of cookies; however, this may reduce or even hinder certain elements of the website and app’s functionality. Likewise, the user can choose the category of cookies they wish to activate in the cookies banner that appears the first time they access the website.
        </p>
        <p dir="ltr" className="text-gray-700 text-base mb-2">
            <strong className="text-lg">1. What is a cookie?</strong>
        </p>
        <p dir="ltr" className="text-gray-700 text-base mb-4">
            A cookie is a small text file that is stored on your computer or other device such as a mobile phone or tablet by a website or app. Cookies allow the website or app to remember your preferences while you visit the website or app to give you a more personalised experience. They help make websites and apps more efficient and can allow site owners to track how the site is used and get feedback on how to make improvements.
        </p>
        <p dir="ltr" className="text-gray-700 text-base mb-4">
            For more general information on cookies, please see the following 
            <a href="https://es.wikipedia.org/wiki/Cookie_(inform%C3%A1tica)" className="text-blue-500 hover:underline inline "> article</a>.
        </p>
        <p dir="ltr" className="text-gray-700 text-base mb-2">
            <strong className="text-lg">2. How does the Safekidly website use cookies?</strong>
        </p>
        <p dir="ltr" className="text-gray-700 text-base mb-4">
            We use cookies to improve the functionality of our website and app and to collect information about how users use them to enable us to continually improve our users’ experience.
        </p>
        <p dir="ltr" className="text-gray-700 text-base mb-4">
            We use technical cookies, which are strictly necessary for the operation of the website, as soon as you open our website. We use other types of cookies only if you give us your consent.
        </p>
        <p dir="ltr" className="text-gray-700 text-base mb-4">
            No information such as your address, password, credit card details, etc. is stored in the cookies used by our website.
        </p>
        <p dir="ltr" className="text-gray-700 text-base mb-2">
        <strong className="text-lg">3. What cookies does the Safekidly website use?</strong>
      </p>
      <p dir="ltr" className="text-gray-700 text-base mb-4">
        The website and app use both own and third-party cookies:
      </p>
      <p dir="ltr" className="text-gray-700 text-base mb-4">
        a) <strong>Own cookies</strong>: cookies sent to your device by us through the web domain.
      </p>
      <p dir="ltr" className="text-gray-700 text-base mb-4">
        b) <strong>Third-party cookies</strong>: they are sent to your device by domains that are not managed by us, but by another entity that processes the data collected through cookies.
      </p>
      <p dir="ltr" className="text-gray-700 text-base mb-4">
        According to the <span className="underline">purpose</span> of the cookies, the cookies used can be divided into the following categories:
      </p>
      <ul className="list-disc list-inside text-gray-700 text-base mb-4">
        <li>
          <strong>Technical cookies (necessary)</strong>: cookies necessary for navigation and for the proper functioning of the website. Their use allows basic functions, such as controlling data traffic and communication, identifying the session, remembering the items added to the cart, completing the payment process, managing payment, controlling any fraud linked to service security, and ensuring secure navigation. The legal basis for collecting data through these cookies is the execution of the contract (the Terms and Conditions) applicable to all users and Safekidly's legitimate interest in managing the website and app.
        </li>
        <li>
          <strong>Preference cookies</strong>: allow remembering information that modifies the behavior or appearance of the website or app itself, such as your preferred language or the region in which the user is located. The legitimate basis for collecting data through these cookies is the consent of the user.
        </li>
        <li>
          <strong>Analytical cookies</strong>: cookies that allow Safekidly to perform follow-up and analyses of user behavior on the website and app; this includes a quantitative measurement of the impact of an advertisement. The information collected through these cookies is used for measuring the activity of the website and app and for profiling users’ browsing to improve them. The legal basis for collecting this data is the consent of the user.
        </li>
        <li>
          <strong>Advertising cookies</strong>: cookies that store information on user behavior obtained through continuous observation of their browsing habits, allowing the development of a specific profile to display advertisements appropriate to this profile. The legitimate basis for collecting this data through these cookies is the consent of the user.
        </li>
      </ul>
      <p dir="ltr" className="text-gray-700 text-base mb-4">
        Furthermore, we use both session and persistent cookies. Indeed, depending on the period of time that any cookie is active in the User device, the following categories may be identified:
      </p>
      <p dir="ltr" className="text-gray-700 text-base mb-4">
        a) <strong>Session cookies</strong>: those designed to collect and store data while users access our website. They are regularly used to store information that is only relevant with regard to the specific session, and disappear once the session is closed.
      </p>
      <p dir="ltr" className="text-gray-700 text-base mb-4">
        b) <strong>Persistent cookies</strong>: cookies on which data stay stored and may be accessed and processed for a period of time defined by Safekidly, which may range from a few minutes to several years.
      </p>
      <p dir="ltr" className="text-gray-700 text-base mb-4">
        <span style={{ fontWeight: '400' }}>Technical:</span> These are cookies necessary for navigation and for the proper functioning of the Website. Their use allows basic functions, such as controlling data traffic and communication, identifying the session, remembering the items added to the cart, completing the payment process, managing payment, and controlling any fraud linked to service security.
      </p>
      <p dir="ltr" className="text-gray-700 text-base mb-4">
        You can find the <span className="underline">detailed list of the cookies we use, the type, purpose and duration of each one of them in the table of cookies below</span>.
      </p>
      <p dir="ltr" className="text-gray-700 text-base mb-4">
        <strong>&nbsp; &nbsp; &nbsp;4. Can the use of cookies be deactivated?</strong>
      </p>
      <p dir="ltr" className="text-gray-700 text-base mb-4">
        Users may restrict or limit the use of cookies by adjusting the settings of their browser. However, if you restrict cookies, you may not be able to access certain parts of the Website, which will result in less efficient navigation, and you may not be able to take advantage of some of the services available. Therefore, it is recommended that you do not disable them.
      </p>
      <p dir="ltr" className="text-gray-700 text-base mb-4">
        If you wish to disable cookies, go to your browser preferences menu or browser settings and look for the privacy section. As an example, you can follow the instructions below:
      </p>
      <ul className="list-disc pl-2 text-gray-700 mb-4">
      <li className="flex items-center">
      < GoDotFill  className="h-4 w-4 text-gray-500 mr-2" />
        <a
          href="https://support.microsoft.com/es-es/microsoft-edge/eliminar-las-cookies-en-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09#:~:text=Abre%20Microsoft%20Edge%20y%20selecciona,sitio%20y%20luego%20Borrar%20ahora"
          className="text-blue-500 hover:underline"
        >
          Microsoft Edge
        </a>
      </li>
      <li className="flex items-center">
      < GoDotFill  className="h-4 w-4 text-gray-500 mr-2" />
        <a
          href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
          className="text-blue-500 hover:underline"
        >
          Firefox
        </a>
      </li>
      <li className="flex items-center">
        < GoDotFill  className="h-4 w-4 text-gray-500 mr-2" />
        <a
          href="https://support.apple.com/en-gb/HT201265"
          className="text-blue-500 hover:underline"
        >
          Safari
        </a>
      </li>
      <li className="flex items-center">
      < GoDotFill  className="h-4 w-4 text-gray-500 mr-2" />
        <a
          href="https://support.google.com/chrome/answer/95647?hl=en"
          className="text-blue-500 hover:underline"
        >
          Google Chrome
        </a>
      </li>
    </ul>
      <p dir="ltr" className="text-gray-700 text-base mb-4">
        If you have any questions about our use of cookies, you can contact us at <a href="mailto:info@prabhaktech.com" className="text-blue-500 hover:underline inline ">info@prabhaktech.com</a>.
      </p>
      <p dir="ltr" className="text-gray-700 text-base mb-4 inline ">
        Your consent applies to the following domains: 
        <a href="https://www.prabhaktech.com" className="text-blue-500 hover:underline inline "> www.prabhaktech.com</a>, 
      </p>

      <h4 className="text-lg font-bold mt-4" dir="ltr">
        Cookies
      </h4>
      <p className=" my-4">
        <span className="font-normal">Technical:</span>
      </p>
      <table class="w-full overflow-auto  h-auto border-collapse border border-gray-300">
  <thead>
    <tr class="bg-gray-100">
      <th class="border border-gray-300 px-4 py-2 text-left text-gray-700 font-bold">Name</th>
      <th class="border border-gray-300 px-4 py-2 text-left text-gray-700 font-bold">Provider</th>
      <th class="border border-gray-300 px-4 py-2 text-left text-gray-700 font-bold">Purpose</th>
      <th class="border border-gray-300 px-4 py-2 text-left text-gray-700 font-bold">Expiry</th>
      <th class="border border-gray-300 px-4 py-2 text-left text-gray-700 font-bold">Type</th>
    </tr>
  </thead>
  <tbody>
    <tr class="hover:bg-gray-50">
      <td class="border border-gray-300 px-4 py-2 text-gray-700">CookieConsent [x4]</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">
        <a href="https://www.cookiebot.com/goto/privacy-policy/" class="text-gray-700">Cookiebot</a>
      </td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Stores the user’s cookie consent state for the current domain</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">430 months</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">HTTP</td>
    </tr>
    <tr class="hover:bg-gray-50">
      <td class="border border-gray-300 px-4 py-2 text-gray-700">CONSENT [x2]</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">
        <a href="https://policies.google.com/privacy" class="text-gray-700">Google</a>, 
        <a href="https://policies.google.com/privacy" class="text-gray-700">YouTube</a>
      </td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Used to detect if the visitor has accepted the marketing category in the cookie banner.</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">2 years</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">HTTP</td>
    </tr>
    <tr class="hover:bg-gray-50">
      <td class="border border-gray-300 px-4 py-2 text-gray-700">rc::a</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">info@prabhaktech.com</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Used to distinguish between humans and bots.</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Persistent</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">HTML</td>
    </tr>
    <tr class="hover:bg-gray-50">
      <td class="border border-gray-300 px-4 py-2 text-gray-700">rc::c</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">info@prabhaktech.com</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Used to distinguish between humans and bots.</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Session</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">HTML</td>
    </tr>
    <tr class="hover:bg-gray-50">
      <td class="border border-gray-300 px-4 py-2 text-gray-700">gdpr</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">
        <a href="https://www.id5.io/privacy" class="text-gray-700">ID5</a>
      </td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Determines whether the visitor has accepted the cookie consent box.</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Session</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">HTTP</td>
    </tr>
    <tr class="hover:bg-gray-50">
      <td class="border border-gray-300 px-4 py-2 text-gray-700">1.gif</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">
        <a href="https://www.cookiebot.com/goto/privacy-policy/" class="text-gray-700">Cookiebot</a>
      </td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Used to count the number of sessions to the website.</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Session</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Pixel</td>
    </tr>
    <tr class="hover:bg-gray-50">
      <td class="border border-gray-300 px-4 py-2 text-gray-700">li_gc</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">
        <a href="https://www.linkedin.com/legal/privacy-policy" class="text-gray-700">LinkedIn</a>
      </td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Stores the user’s cookie consent state for the current domain</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">180 days</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">HTTP</td>
    </tr>
    <tr class="hover:bg-gray-50">
      <td class="border border-gray-300 px-4 py-2 text-gray-700">opt_out</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">
        <a href="https://www.nativo.com/privacy-policy" class="text-gray-700">Nativo</a>
      </td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Used to detect if the visitor has accepted the marketing category.</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">1 year</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">HTTP</td>
    </tr>
    <tr class="hover:bg-gray-50">
      <td class="border border-gray-300 px-4 py-2 text-gray-700">ps_session</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">mdm.prabhaktech.com</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Preserves user session state across page requests.</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">1 day</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">HTTP</td>
    </tr>
    <tr class="hover:bg-gray-50">
      <td class="border border-gray-300 px-4 py-2 text-gray-700">__cf_bm</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">
        <a href="https://vimeo.com/privacy" class="text-gray-700">Vimeo</a>
      </td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Used to distinguish between humans and bots.</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">1 day</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">HTTP</td>
    </tr>
    <tr class="hover:bg-gray-50">
      <td class="border border-gray-300 px-4 py-2 text-gray-700">bscookie</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">
        <a href="https://www.linkedin.com/legal/privacy-policy" class="text-gray-700">LinkedIn</a>
      </td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Used to identify the visitor through an application.</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">1 year</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">HTTP</td>
    </tr>
    <tr class="hover:bg-gray-50">
      <td class="border border-gray-300 px-4 py-2 text-gray-700">_q_language [x2]</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">info@prabhaktech.com</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Remembers user’s language</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">30 days</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">HTTP</td>
    </tr>
    <tr class="hover:bg-gray-50">
      <td class="border border-gray-300 px-4 py-2 text-gray-700">wp-api-schema-modelhttps://#.#/#/</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">mdm.prabhaktech.com</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Required by WordPress to function correctly</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">Session</td>
      <td class="border border-gray-300 px-4 py-2 text-gray-700">HTML</td>
    </tr>
  </tbody>
      </table>

      <p className="my-6">
        <span className="font-normal">Preferences:&nbsp;</span>
      </p>

      <table className="min-w-full border-collapse border border-gray-200">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-200 px-4 py-2 text-left font-bold">Name</th>
            <th className="border border-gray-200 px-4 py-2 text-left font-bold">Provider</th>
            <th className="border border-gray-200 px-4 py-2 text-left font-bold">Purpose</th>
            <th className="border border-gray-200 px-4 py-2 text-left font-bold">Expiry</th>
            <th className="border border-gray-200 px-4 py-2 text-left font-bold">Type</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-200 px-4 py-2">
              <p><span className="font-normal">adroll#adroll [x2]</span></p>
            </td>
            <td className="border border-gray-200 px-4 py-2">
              <span className="font-normal">info@prabhaktech.com, auth-pre.mdm.prabhaktech.com</span>
            </td>
            <td className="border border-gray-200 px-4 py-2">
              <span className="font-normal">Stores the user’s cookie consent state for the current domain</span>
            </td>
            <td className="border border-gray-200 px-4 py-2">
              <span className="font-normal">Persistent</span>
            </td>
            <td className="border border-gray-200 px-4 py-2">
              <span className="font-normal">IDB</span>
            </td>
          </tr>
        </tbody>
      </table>
    

      <p className="my-6">
        <span className="font-normal">Analytics:</span>
      </p>
      <table className="min-w-full bg-gray-100 border border-gray-300">
            <thead>
                <tr className="bg-gray-200 text-gray-600">
                    <th className="py-3 px-4 border-b">Name</th>
                    <th className="py-3 px-4 border-b">Provider</th>
                    <th className="py-3 px-4 border-b">Purpose</th>
                    <th className="py-3 px-4 border-b">Expiry</th>
                    <th className="py-3 px-4 border-b">Type</th>
                </tr>
            </thead>
            <tbody>
                <tr className="text-gray-600 hover:bg-gray-50">
                    <td className="py-2 px-4 border-b">bounce</td>
                    <td className="py-2 px-4 border-b">info@prabhaktech.com</td>
                    <td className="py-2 px-4 border-b">Determines if a user leaves the website straight away. This information is used for internal statistics and analytics by the website operator.</td>
                    <td className="py-2 px-4 border-b">Session</td>
                    <td className="py-2 px-4 border-b">Pixel</td>
                </tr>
                <tr className="text-gray-600 hover:bg-gray-50">
                    <td className="py-2 px-4 border-b">dicbo_id [x2]</td>
                    <td className="py-2 px-4 border-b"><a href="https://www.outbrain.com/legal/privacy#privacy-policy" className="text-blue-600 hover:underline">Outbrain</a></td>
                    <td className="py-2 px-4 border-b">Collects statistics concerning the visitors’ use of the website and its general functionality. This is used to optimize and compile reports on the website for comparison through a third party analysis service.</td>
                    <td className="py-2 px-4 border-b">1 day</td>
                    <td className="py-2 px-4 border-b">HTTP</td>
                </tr>
                <tr className="text-gray-600 hover:bg-gray-50">
                    <td className="py-2 px-4 border-b">S</td>
                    <td className="py-2 px-4 border-b"><a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">Google</a></td>
                    <td className="py-2 px-4 border-b">Sets a unique ID for the session. This allows the website to obtain data on visitor behaviour for statistical purposes.</td>
                    <td className="py-2 px-4 border-b">1 day</td>
                    <td className="py-2 px-4 border-b">HTTP</td>
                </tr>
                <tr className="text-gray-600 hover:bg-gray-50">
                    <td className="py-2 px-4 border-b">__utm.gif</td>
                    <td className="py-2 px-4 border-b">info@prabhaktech.com</td>
                    <td className="py-2 px-4 border-b">Google Analytics Tracking Code that logs details about the visitor’s browser and computer.</td>
                    <td className="py-2 px-4 border-b">Session</td>
                    <td className="py-2 px-4 border-b">Pixel</td>
                </tr>
                <tr className="text-gray-600 hover:bg-gray-50">
                    <td className="py-2 px-4 border-b">IDE</td>
                    <td className="py-2 px-4 border-b"><a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">Google</a></td>
                    <td className="py-2 px-4 border-b">Used by Google DoubleClick to register and report the website user’s actions after viewing or clicking one of the advertiser’s ads with the purpose of measuring the efficacy of an ad and to present targeted ads to the user.</td>
                    <td className="py-2 px-4 border-b">1 year</td>
                    <td className="py-2 px-4 border-b">HTTP</td>
                </tr>
                <tr className="text-gray-600 hover:bg-gray-50">
                    <td className="py-2 px-4 border-b">test_cookie</td>
                    <td className="py-2 px-4 border-b"><a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">Google</a></td>
                    <td className="py-2 px-4 border-b">Used to check if the user’s browser supports cookies.</td>
                    <td className="py-2 px-4 border-b">1 day</td>
                    <td className="py-2 px-4 border-b">HTTP</td>
                </tr>
                <tr className="text-gray-600 hover:bg-gray-50">
                    <td className="py-2 px-4 border-b">collect</td>
                    <td className="py-2 px-4 border-b"><a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">Google</a></td>
                    <td className="py-2 px-4 border-b">Used to send data to Google Analytics about the visitor’s device and behavior. Tracks the visitor across devices and marketing channels.</td>
                    <td className="py-2 px-4 border-b">Session</td>
                    <td className="py-2 px-4 border-b">Pixel</td>
                </tr>
                <tr className="text-gray-600 hover:bg-gray-50">
                    <td className="py-2 px-4 border-b">hjActiveViewportIds</td>
                    <td className="py-2 px-4 border-b"><a href="https://www.hotjar.com/legal/policies/privacy/" className="text-blue-600 hover:underline">Hotjar</a></td>
                    <td className="py-2 px-4 border-b">This cookie contains an ID string on the current session. This contains non-personal information on what subpages the visitor enters – this information is used to optimize the visitor’s experience.</td>
                    <td className="py-2 px-4 border-b">Persistent</td>
                    <td className="py-2 px-4 border-b">HTML</td>
                </tr>
                <tr className="text-gray-600 hover:bg-gray-50">
                    <td className="py-2 px-4 border-b">hjViewportId</td>
                    <td className="py-2 px-4 border-b"><a href="https://www.hotjar.com/legal/policies/privacy/" className="text-blue-600 hover:underline">Hotjar</a></td>
                    <td className="py-2 px-4 border-b">Saves the user’s screen size in order to adjust the size of images on the website.</td>
                    <td className="py-2 px-4 border-b">Session</td>
                    <td className="py-2 px-4 border-b">HTML</td>
                </tr>
                <tr className="text-gray-600 hover:bg-gray-50">
                    <td className="py-2 px-4 border-b">AnalyticsSyncHistory</td>
                    <td className="py-2 px-4 border-b"><a href="https://www.linkedin.com/legal/privacy-policy" className="text-blue-600 hover:underline">LinkedIn</a></td>
                    <td className="py-2 px-4 border-b">Used in connection with data-synchronization with third-party analysis service.</td>
                    <td className="py-2 px-4 border-b">30 days</td>
                    <td className="py-2 px-4 border-b">HTTP</td>
                </tr>
                <tr className="text-gray-600 hover:bg-gray-50">
                    <td className="py-2 px-4 border-b">cksync.php [x2]</td>
                    <td className="py-2 px-4 border-b">
                        <a href="https://www.media.net/privacy-policy" className="text-blue-600 hover:underline">Media.net</a>, 
                        <a href="https://policies.oath.com/us/en/oath/privacy/index.html" className="text-blue-600 hover:underline"> Yahoo</a>
                    </td>
                    <td className="py-2 px-4 border-b">This cookie is used to determine if cookie data synchronization is enabled or disabled – cookie data synchronization is used to synchronize and gather visitor data on several domains.</td>
                    <td className="py-2 px-4 border-b">Session</td>
                    <td className="py-2 px-4 border-b">HTTP</td>
                </tr>
            </tbody>
        </table>




      <p className="my-6">
        <span className=" font-normal">Advertising:</span>
      </p>
      <table className="min-w-full  overflow-x-auto  divide-y border divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Provider</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Purpose</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Expiry</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
 
        <tr>
            <td className="px-6 py-4 ">_uetsid [x2]</td>
            <td className="px-6 py-4 ">
              www.mdm.prabhaktech.com<br />
              <a href="https://privacy.microsoft.com/en-US/privacystatement" className="text-blue-600 hover:underline">Microsoft</a>
            </td>
            <td className="px-6 py-4 ">Used to track visitors on multiple websites, in order to present relevant advertisement based on the visitor’s preferences.</td>
            <td className="px-6 py-4 ">Persistent</td>
            <td className="px-6 py-4 ">HTML</td>
          </tr>
          <tr>
            <td className="px-6 py-4 ">_uetsid_exp [x2]</td>
            <td className="px-6 py-4 ">
              www.mdm.prabhaktech.com<br />
              <a href="https://privacy.microsoft.com/en-US/privacystatement" className="text-blue-600 hover:underline">Microsoft</a>
            </td>
            <td className="px-6 py-4 ">Contains the expiry-date for the cookie with corresponding name.</td>
            <td className="px-6 py-4 ">Persistent</td>
            <td className="px-6 py-4 ">HTML</td>
          </tr>
          <tr>
            <td className="px-6 py-4 ">_uetvid [x2]</td>
            <td className="px-6 py-4 ">
              www.mdm.prabhaktech.com<br />
              <a href="https://privacy.microsoft.com/en-US/privacystatement" className="text-blue-600 hover:underline">Microsoft</a>
            </td>
            <td className="px-6 py-4">Used to track visitors on multiple websites, in order to present relevant advertisement based on the visitor’s preferences.</td>
            <td className="px-6 py-4">Persistent</td>
            <td className="px-6 py-4">HTML</td>
          </tr>
          <tr>
            <td className="px-6 py-4">_uetvid_exp [x2]</td>
            <td className="px-6 py-4">
              www.mdm.prabhaktech.com<br />
              <a href="https://privacy.microsoft.com/en-US/privacystatement" className="text-blue-600 hover:underline">Microsoft</a>
            </td>
            <td className="px-6 py-4">Contains the expiry-date for the cookie with corresponding name.</td>
            <td className="px-6 py-4">Persistent</td>
            <td className="px-6 py-4">HTML</td>
          </tr>



          <tr className="h-24">
            <td className="px-6 py-4 text-gray-800">match</td>
            <td className="px-6 py-4">
              <a href="https://improvedigital.com/privacy-policy/" className="text-gray-800">Improve Digital</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Sets a unique ID for the visitor, that allows third party advertisers to target the visitor with relevant advertisement. This pairing service is provided by third party advertisement hubs, which facilitates real-time bidding for advertisers.</td>
            <td className="px-6 py-4 text-gray-800">Session</td>
            <td className="px-6 py-4 text-gray-800">Pixel</td>
          </tr>
          <tr className="h-18">
            <td className="px-6 py-4 text-gray-800">xuid</td>
            <td className="px-6 py-4">
              <a href="https://triplelift.com/privacy/" className="text-gray-800">Triplelift</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Used to present the visitor with relevant content and advertisement – The service is provided by third party advertisement hubs, which facilitate real-time bidding for advertisers.</td>
            <td className="px-6 py-4 text-gray-800">Session</td>
            <td className="px-6 py-4 text-gray-800">Pixel</td>
          </tr>
          <tr className="h-18">
            <td className="px-6 py-4 text-gray-800">pixel</td>
            <td className="px-6 py-4">
              <a href="https://site.adform.com/privacy-center/platform-privacy/opt-out/" className="text-gray-800">Adform</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Collects information on user behaviour on multiple websites. This information is used in order to optimize the relevance of advertisement on the website.</td>
            <td className="px-6 py-4 text-gray-800">Session</td>
            <td className="px-6 py-4 text-gray-800">Pixel</td>
          </tr>
          <tr className="h-12">
            <td className="px-6 py-4 text-gray-800">anj</td>
            <td className="px-6 py-4">
              <a href="https://www.appnexus.com/corporate-privacy-policy" className="text-gray-800">Appnexus</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Registers a unique ID that identifies a returning user’s device. The ID is used for targeted ads.</td>
            <td className="px-6 py-4 text-gray-800">3 months</td>
            <td className="px-6 py-4 text-gray-800">HTTP</td>
          </tr>


          <tr className="h-54">
            <td className="px-6 py-4 text-gray-800">receive-cookie-deprecation [x5]</td>
            <td className="px-6 py-4">
              <div className="space-y-1">
                <a href="https://www.appnexus.com/corporate-privacy-policy" className="text-gray-800">Appnexus</a>
                <a href="https://www.criteo.com/privacy/corporate-privacy-policy/" className="text-gray-800">Criteo</a>
                <a href="https://policies.google.com/privacy" className="text-gray-800">Google</a>
                <a href="https://www.nextroll.com/privacy" className="text-gray-800">Adroll Group</a>
                <a href="https://www.nextroll.com/privacy" className="text-gray-800">Adroll</a>
              </div>
            </td>
            <td className="px-6 py-4 text-gray-800">Pending</td>
            <td className="px-6 py-4 text-gray-800">400 days</td>
            <td className="px-6 py-4 text-gray-800">HTTP</td>
          </tr>
          <tr className="h-24">
            <td className="px-6 py-4 text-gray-800">setuid</td>
            <td className="px-6 py-4">
              <a href="https://www.appnexus.com/corporate-privacy-policy" className="text-gray-800">Appnexus</a>
            </td>
            <td className="px-6 py-4 text-gray-800">This cookie is set by the audience manager of the website to determine the time and frequencies of visitor data synchronization – cookie data synchronization is used to synchronize and gather visitor data from several websites.</td>
            <td className="px-6 py-4 text-gray-800">Session</td>
            <td className="px-6 py-4 text-gray-800">Pixel</td>
          </tr>
          <tr className="h-12">
            <td className="px-6 py-4 text-gray-800">uuid2</td>
            <td className="px-6 py-4">
              <a href="https://www.appnexus.com/corporate-privacy-policy" className="text-gray-800">Appnexus</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Registers a unique ID that identifies a returning user’s device. The ID is used for targeted ads.</td>
            <td className="px-6 py-4 text-gray-800">3 months</td>
            <td className="px-6 py-4 text-gray-800">HTTP</td>
          </tr>
          <tr className="h-12">
            <td className="px-6 py-4 text-gray-800">XANDR_PANID</td>
            <td className="px-6 py-4">
              <a href="https://www.appnexus.com/corporate-privacy-policy" className="text-gray-800">Appnexus</a>
            </td>
            <td className="px-6 py-4 text-gray-800">This cookie registers data on the visitor. The information is used to optimize advertisement relevance.</td>
            <td className="px-6 py-4 text-gray-800">3 months</td>
            <td className="px-6 py-4 text-gray-800">HTTP</td>
          </tr>
          <tr className="h-12">
            <td className="px-6 py-4 text-gray-800">__adroll_shared</td>
            <td className="px-6 py-4">
              <a href="https://www.nextroll.com/privacy" className="text-gray-800">Adroll Group</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Collects data on the user across websites – This data is used to make advertisement more relevant.</td>
            <td className="px-6 py-4 text-gray-800">13 months</td>
            <td className="px-6 py-4 text-gray-800">HTTP</td>
          </tr>



          <tr className="h-18">
            <td className="px-6 py-4 text-gray-800">__adroll_consent_params [x2]</td>
            <td className="px-6 py-4">
              <a href="https://www.nextroll.com/privacy" className="text-gray-800">Adroll Group</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Collects information on user behaviour on multiple websites. This information is used in order to optimize the relevance of advertisement on the website.</td>
            <td className="px-6 py-4 text-gray-800">Session</td>
            <td className="px-6 py-4 text-gray-800">HTTP</td>
          </tr>
          <tr className="h-24">
            <td className="px-6 py-4 text-gray-800">__adroll_fpc [x2]</td>
            <td className="px-6 py-4">
              <a href="https://www.nextroll.com/privacy" className="text-gray-800">Adroll Group</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Used to identify the visitor across visits and devices. This allows the website to present the visitor with relevant advertisement – The service is provided by third party advertisement hubs, which facilitate real-time bidding for advertisers.</td>
            <td className="px-6 py-4 text-gray-800">1 year</td>
            <td className="px-6 py-4 text-gray-800">HTTP</td>
          </tr>
          <tr className="h-12">
            <td className="px-6 py-4 text-gray-800">__ar_v4 [x2]</td>
            <td className="px-6 py-4">
              <a href="https://www.nextroll.com/privacy" className="text-gray-800">Adroll Group</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Optimises ad display based on the user’s movement combined and various advertiser bids for displaying user ads.</td>
            <td className="px-6 py-4 text-gray-800">1 year</td>
            <td className="px-6 py-4 text-gray-800">HTTP</td>
          </tr>
          <tr className="h-12">
            <td className="px-6 py-4 text-gray-800">_te_ [x2]</td>
            <td className="px-6 py-4">
              <a href="https://www.nextroll.com/privacy" className="text-gray-800">Adroll Group</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Registers a unique ID that identifies a returning user’s device. The ID is used for targeted ads.</td>
            <td className="px-6 py-4 text-gray-800">Session</td>
            <td className="px-6 py-4 text-gray-800">HTTP</td>
          </tr>
          <tr className="h-12">
            <td className="px-6 py-4 text-gray-800">MSPTC</td>
            <td className="px-6 py-4">
              <a href="https://privacy.microsoft.com/en-US/privacystatement" className="text-gray-800">Microsoft</a>
            </td>
            <td className="px-6 py-4 text-gray-800">This cookie registers data on the visitor. The information is used to optimize advertisement relevance.</td>
            <td className="px-6 py-4 text-gray-800">1 year</td>
            <td className="px-6 py-4 text-gray-800">HTTP</td>
          </tr>


          <tr className="h-18">
            <td className="px-6 py-4 text-gray-800">MUID</td>
            <td className="px-6 py-4">
              <a href="https://privacy.microsoft.com/en-US/privacystatement" className="text-gray-800">Microsoft</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Used widely by Microsoft as a unique user ID. The cookie enables user tracking by synchronising the ID across many Microsoft domains.</td>
            <td className="px-6 py-4 text-gray-800">1 year</td>
            <td className="px-6 py-4 text-gray-800">HTTP</td>
          </tr>
          <tr className="h-24">
            <td className="px-6 py-4 text-gray-800">CMID</td>
            <td className="px-6 py-4">
              <a href="http://www.casalemedia.com/" className="text-gray-800">Casale Media</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Collects visitor data related to the user’s visits to the website, such as the number of visits, average time spent on the website and what pages have been loaded, with the purpose of displaying targeted ads.</td>
            <td className="px-6 py-4 text-gray-800">1 year</td>
            <td className="px-6 py-4 text-gray-800">HTTP</td>
          </tr>
          <tr className="h-24">
            <td className="px-6 py-4 text-gray-800">CMPRO</td>
            <td className="px-6 py-4">
              <a href="http://www.casalemedia.com/" className="text-gray-800">Casale Media</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Collects data on visitor behaviour from multiple websites, in order to present more relevant advertisement – This also allows the website to limit the number of times that the visitor is shown the same advertisement.</td>
            <td className="px-6 py-4 text-gray-800">3 months</td>
            <td className="px-6 py-4 text-gray-800">HTTP</td>
          </tr>
          <tr className="h-24">
            <td className="px-6 py-4 text-gray-800">CMPS</td>
            <td className="px-6 py-4">
              <a href="http://www.casalemedia.com/" className="text-gray-800">Casale Media</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Collects visitor data related to the user’s visits to the website, such as the number of visits, average time spent on the website and what pages have been loaded, with the purpose of displaying targeted ads.</td>
            <td className="px-6 py-4 text-gray-800">3 months</td>
            <td className="px-6 py-4 text-gray-800">HTTP</td>
          </tr>
          <tr className="h-18">
            <td className="px-6 py-4 text-gray-800">rum</td>
            <td className="px-6 py-4">
              <a href="http://www.casalemedia.com/" className="text-gray-800">Casale Media</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Collects data related to the user’s visits to the website, such as the number of visits, average time spent on the website and what pages have been loaded, with the purpose of displaying targeted ads.</td>
            <td className="px-6 py-4 text-gray-800">Session</td>
            <td className="px-6 py-4 text-gray-800">Pixel</td>
          </tr>


        <tr className="h-6">
            <td className="px-6 py-4 text-gray-800">lastExternalReferrer</td>
            <td className="px-6 py-4">
              <a href="https://www.facebook.com/policy.php/" className="text-gray-800">Meta Platforms, Inc.</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Pending</td>
            <td className="px-6 py-4 text-gray-800">Persistent</td>
            <td className="px-6 py-4 text-gray-800">HTML</td>
          </tr>
          <tr className="h-6">
            <td className="px-6 py-4 text-gray-800">lastExternalReferrerTime</td>
            <td className="px-6 py-4">
              <a href="https://www.facebook.com/policy.php/" className="text-gray-800">Meta Platforms, Inc.</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Pending</td>
            <td className="px-6 py-4 text-gray-800">Persistent</td>
            <td className="px-6 py-4 text-gray-800">HTML</td>
          </tr>
          <tr className="h-18">
            <td className="px-6 py-4 text-gray-800">cto_bundle</td>
            <td className="px-6 py-4">
              <a href="https://www.criteo.com/privacy/corporate-privacy-policy/" className="text-gray-800">Criteo</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Presents the user with relevant content and advertisement. The service is provided by third-party advertisement hubs, which facilitate real-time bidding for advertisers.</td>
            <td className="px-6 py-4 text-gray-800">Persistent</td>
            <td className="px-6 py-4 text-gray-800">HTML</td>
          </tr>
          <tr className="h-12">
            <td className="px-6 py-4 text-gray-800">optout</td>
            <td className="px-6 py-4">
              <a href="https://www.criteo.com/privacy/corporate-privacy-policy/" className="text-gray-800">Criteo</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Identifies if the visitor has deselected any cookies, trackers or other audience targeting tools.</td>
            <td className="px-6 py-4 text-gray-800">Session</td>
            <td className="px-6 py-4 text-gray-800">HTTP</td>
          </tr>
          <tr className="h-24">
            <td className="px-6 py-4 text-gray-800">uid</td>
            <td className="px-6 py-4">
              <a href="https://www.criteo.com/privacy/corporate-privacy-policy/" className="text-gray-800">Criteo</a>
            </td>
            <td className="px-6 py-4 text-gray-800">Collects visitor data related to the user’s visits to the website, such as the number of visits, average time spent on the website and what pages have been loaded, with the purpose of displaying targeted ads.</td>
            <td className="px-6 py-4 text-gray-800">1 year</td>
            <td className="px-6 py-4 text-gray-800">HTTP</td>
          </tr>


          <tr>
            <td className="px-6 py-4  ">iex</td>
            <td className="px-6 py-4  ">
              <a href="https://www.criteo.com/privacy/corporate-privacy-policy/" className="text-blue-600 hover:underline">Criteo</a>
            </td>
            <td className="px-6 py-4  ">Used to track visitors on multiple websites, in order to present relevant advertisement based on the visitor’s preferences.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">Pixel</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">__adroll</td>
            <td className="px-6 py-4  ">
              <a href="https://www.nextroll.com/privacy" className="text-blue-600 hover:underline">Adroll</a>
            </td>
            <td className="px-6 py-4  ">Registers a unique ID that identifies a returning user’s device. The ID is used for targeted ads.</td>
            <td className="px-6 py-4  ">13 months</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">cm/#/out</td>
            <td className="px-6 py-4  ">
              <a href="https://www.nextroll.com/privacy" className="text-blue-600 hover:underline">Adroll</a>
            </td>
            <td className="px-6 py-4  ">Collects information on user behaviour on multiple websites. This information is used in order to optimize the relevance of advertisement on the website.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">Pixel</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">demconf.jpg</td>
            <td className="px-6 py-4  ">
              <a href="https://www.adobe.com/privacy.html" className="text-blue-600 hover:underline">Adobe Inc.</a>
            </td>
            <td className="px-6 py-4  ">Sets a unique ID for the visitor, that allows third party advertisers to target the visitor with relevant advertisement. This pairing service is provided by third party advertisement hubs, which facilitates real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">Pixel</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">demdex</td>
            <td className="px-6 py-4  ">
              <a href="https://www.adobe.com/privacy.html" className="text-blue-600 hover:underline">Adobe Inc.</a>
            </td>
            <td className="px-6 py-4  ">Via a unique ID that is used for semantic content analysis, the user’s navigation on the website is registered and linked to offline data from surveys and similar registrations to display targeted ads.</td>
            <td className="px-6 py-4  ">180 days</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>


          <tr>
            <td className="px-6 py-4  ">dpm</td>
            <td className="px-6 py-4  ">
              <a href="https://www.adobe.com/privacy.html" className="text-blue-600 hover:underline">Adobe Inc.</a>
            </td>
            <td className="px-6 py-4  ">Sets a unique ID for the visitor, that allows third party advertisers to target the visitor with relevant advertisement. This pairing service is provided by third party advertisement hubs, which facilitates real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">180 days</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">COMPASS</td>
            <td className="px-6 py-4  ">
              <a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">Google</a>
            </td>
            <td className="px-6 py-4  ">Pending</td>
            <td className="px-6 py-4  ">1 day</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">GFE_RTT</td>
            <td className="px-6 py-4  ">
              <a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">Google</a>
            </td>
            <td className="px-6 py-4  ">Used to implement the content through Google Docs.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">_uetsid [x2]</td>
            <td className="px-6 py-4  ">
              <a href="https://privacy.microsoft.com/en-US/privacystatement" className="text-blue-600 hover:underline">Microsoft</a>
            </td>
            <td className="px-6 py-4  ">Collects data on visitor behaviour from multiple websites, in order to present more relevant advertisement – This also allows the website to limit the number of times that they are shown the same advertisement.</td>
            <td className="px-6 py-4  ">1 day</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">_uetvid [x2]</td>
            <td className="px-6 py-4  ">
              <a href="https://privacy.microsoft.com/en-US/privacystatement" className="text-blue-600 hover:underline">Microsoft</a>
            </td>
            <td className="px-6 py-4  ">Used to track visitors on multiple websites, in order to present relevant advertisement based on the visitor’s preferences.</td>
            <td className="px-6 py-4  ">1 year</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>


          <tr>
            <td className="px-6 py-4  ">activity;xsp=#;ord=#</td>
            <td className="px-6 py-4  ">
              <a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">Google</a>
            </td>
            <td className="px-6 py-4  ">Pending</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">Pixel</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">pagead/landing</td>
            <td className="px-6 py-4  ">
              <a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">Google</a>
            </td>
            <td className="px-6 py-4  ">Collects data on visitor behaviour from multiple websites, in order to present more relevant advertisement – This also allows the website to limit the number of times that they are shown the same advertisement.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">Pixel</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">am_tokens</td>
            <td className="px-6 py-4  ">exchange.mediavine.com</td>
            <td className="px-6 py-4  ">Presents the user with relevant content and advertisement. The service is provided by third-party advertisement hubs, which facilitate real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">14 days</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">am_tokens_eu-v1</td>
            <td className="px-6 py-4  ">exchange.mediavine.com</td>
            <td className="px-6 py-4  ">Presents the user with relevant content and advertisement. The service is provided by third-party advertisement hubs, which facilitate real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">14 days</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">criteo</td>
            <td className="px-6 py-4  ">exchange.mediavine.com</td>
            <td className="px-6 py-4  ">Presents the user with relevant content and advertisement. The service is provided by third-party advertisement hubs, which facilitate real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">14 days</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>

           <tr>
            <td className="px-6 py-4  ">mv_tokens</td>
            <td className="px-6 py-4  ">exchange.mediavine.com</td>
            <td className="px-6 py-4  ">Sets a unique ID for the visitor, that allows third party advertisers to target the visitor with relevant advertisement. This pairing service is provided by third party advertisement hubs, which facilitates real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">14 days</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">mv_tokens_eu-v1</td>
            <td className="px-6 py-4  ">exchange.mediavine.com</td>
            <td className="px-6 py-4  ">Sets a unique ID for the visitor, that allows third party advertisers to target the visitor with relevant advertisement. This pairing service is provided by third party advertisement hubs, which facilitates real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">14 days</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">ads/ga-audiences</td>
            <td className="px-6 py-4  ">
              <a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">Google</a>
            </td>
            <td className="px-6 py-4  ">Pending</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">Pixel</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">NID</td>
            <td className="px-6 py-4  ">
              <a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">Google</a>
            </td>
            <td className="px-6 py-4  ">Registers a unique ID that identifies a returning user’s device. The ID is used for targeted ads.</td>
            <td className="px-6 py-4  ">6 months</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">pagead/1p-user-list/#</td>
            <td className="px-6 py-4  ">
              <a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">Google</a>
            </td>
            <td className="px-6 py-4  ">Pending</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">Pixel</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">callback</td>
            <td className="px-6 py-4  ">
              <a href="https://www.id5.io/privacy" className="text-blue-600 hover:underline">ID5</a>
            </td>
            <td className="px-6 py-4  ">Collects data on visitor behaviour from multiple websites, in order to present more relevant advertisement – This also allows the website to limit the number of times that they are shown the same advertisement.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">car</td>
            <td className="px-6 py-4  ">
              <a href="https://www.id5.io/privacy" className="text-blue-600 hover:underline">ID5</a>
            </td>
            <td className="px-6 py-4  ">Presents the user with relevant content and advertisement. The service is provided by third-party advertisement hubs, which facilitate real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">cf</td>
            <td className="px-6 py-4  ">
              <a href="https://www.id5.io/privacy" className="text-blue-600 hover:underline">ID5</a>
            </td>
            <td className="px-6 py-4  ">Sets a unique ID for the visitor, that allows third party advertisers to target the visitor with relevant advertisement. This pairing service is provided by third party advertisement hubs, which facilitates real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">cip</td>
            <td className="px-6 py-4  ">
              <a href="https://www.id5.io/privacy" className="text-blue-600 hover:underline">ID5</a>
            </td>
            <td className="px-6 py-4  ">Presents the user with relevant content and advertisement. The service is provided by third-party advertisement hubs, which facilitate real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">cnac</td>
            <td className="px-6 py-4  ">
              <a href="https://www.id5.io/privacy" className="text-blue-600 hover:underline">ID5</a>
            </td>
            <td className="px-6 py-4  ">Presents the user with relevant content and advertisement. The service is provided by third-party advertisement hubs, which facilitate real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">gpp</td>
            <td className="px-6 py-4  ">
              <a href="https://www.id5.io/privacy" className="text-blue-600 hover:underline">ID5</a>
            </td>
            <td className="px-6 py-4  ">Pending</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">bcookie</td>
            <td className="px-6 py-4  ">
              <a href="https://www.linkedin.com/legal/privacy-policy" className="text-blue-600 hover:underline">LinkedIn</a>
            </td>
            <td className="px-6 py-4  ">Used by the social networking service, LinkedIn, for tracking the use of embedded services.</td>
            <td className="px-6 py-4  ">1 year</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>

          <tr>
            <td className="px-6 py-4  ">li_sugr</td>
            <td className="px-6 py-4  ">
              <a href="https://www.linkedin.com/legal/privacy-policy" className="text-blue-600 hover:underline">LinkedIn</a>
            </td>
            <td className="px-6 py-4  ">Collects data on user behaviour and interaction in order to optimize the website and make advertisement on the website more relevant.</td>
            <td className="px-6 py-4  ">3 months</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">lidc</td>
            <td className="px-6 py-4  ">
              <a href="https://www.linkedin.com/legal/privacy-policy" className="text-blue-600 hover:underline">LinkedIn</a>
            </td>
            <td className="px-6 py-4  ">Used by the social networking service, LinkedIn, for tracking the use of embedded services.</td>
            <td className="px-6 py-4  ">1 day</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">UserMatchHistory</td>
            <td className="px-6 py-4  ">
              <a href="https://www.linkedin.com/legal/privacy-policy" className="text-blue-600 hover:underline">LinkedIn</a>
            </td>
            <td className="px-6 py-4  ">Used to track visitors on multiple websites, in order to present relevant advertisement based on the visitor’s preferences.</td>
            <td className="px-6 py-4  ">30 days</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">sync</td>
            <td className="px-6 py-4  ">
              <a href="http://www.ividence.com/politique-de-confidentialite/" className="text-blue-600 hover:underline">Ividence</a>
            </td>
            <td className="px-6 py-4  ">Collects data on user behaviour and interaction in order to optimize the website and make advertisement on the website more relevant.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">Pixel</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">data-c</td>
            <td className="px-6 py-4  ">
              <a href="https://www.media.net/privacy-policy" className="text-blue-600 hover:underline">Media.net</a>
            </td>
            <td className="px-6 py-4  ">Presents the user with relevant content and advertisement. The service is provided by third-party advertisement hubs, which facilitate real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">30 days</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">data-c-ts</td>
            <td className="px-6 py-4  ">
              <a href="https://www.media.net/privacy-policy" className="text-blue-600 hover:underline">Media.net</a>
            </td>
            <td className="px-6 py-4  ">Collects data on the user across websites – This data is used to make advertisement more relevant.</td>
            <td className="px-6 py-4  ">30 days</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>

          <tr>
            <td className="px-6 py-4  ">ayl_visitor</td>
            <td className="px-6 py-4  ">omnitagjs.com</td>
            <td className="px-6 py-4  ">This cookie registers data on the visitor. The information is used to optimize advertisement relevance.</td>
            <td className="px-6 py-4  ">30 days</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">visitor/sync</td>
            <td className="px-6 py-4  ">omnitagjs.com</td>
            <td className="px-6 py-4  ">This cookie is used to assign specific visitors into segments, this segmentation is based on visitor behavior on the website – the segmentation can be used to target larger groups.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">Pixel</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">w/1.0/sd</td>
            <td className="px-6 py-4  ">info@prabhaktech.com</td>
            <td className="px-6 py-4  ">Registers data on visitors such as IP addresses, geographical location and advertisement interaction. This information is used optimize the advertisement on websites that make use of OpenX.net services.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">Pixel</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">suid/1017</td>
            <td className="px-6 py-4  ">
              <a href="https://www.nativo.com/privacy-policy" className="text-blue-600 hover:underline">Nativo</a>
            </td>
            <td className="px-6 py-4  ">Presents the user with relevant content and advertisement. The service is provided by third-party advertisement hubs, which facilitate real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">Pixel</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">criteo_write_test [x2]</td>
            <td className="px-6 py-4  ">
              <a href="https://www.criteo.com/privacy/corporate-privacy-policy/" className="text-blue-600 hover:underline">Criteo</a>
            </td>
            <td className="px-6 py-4  ">Sets a unique ID for the visitor, that allows third party advertisers to target the visitor with relevant advertisement. This pairing service is provided by third party advertisement hubs, which facilitates real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">1 day</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">cto_tld_test [x3]</td>
            <td className="px-6 py-4  ">
              <a href="https://www.criteo.com/privacy/corporate-privacy-policy/" className="text-blue-600 hover:underline">Criteo</a>
            </td>
            <td className="px-6 py-4  ">Used to identify the visitor across visits and devices. This allows the website to present the visitor with relevant advertisement – The service is provided by third party advertisement hubs, which facilitate real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>

          <tr>
            <td className="px-6 py-4  ">prism_# [x2]</td>
            <td className="px-6 py-4  ">
              <p>prism.app-us1.com</p>
              <p>diffuser-cdn.app-us1.com</p>
            </td>
            <td className="px-6 py-4  ">Collects information on user preferences and/or interaction with web-campaign content – This is used on CRM-campaign-platform used by website owners for promoting events or products.</td>
            <td className="px-6 py-4  ">30 days</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">AdServer/Pug</td>
            <td className="px-6 py-4  ">info@prabhaktech.com</td>
            <td className="px-6 py-4  ">Sets a timestamp for when the visitor entered the website. This is used for analytical purposes on the website.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">Pixel</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">KRTBCOOKIE_#</td>
            <td className="px-6 py-4  ">
              <a href="https://pubmatic.com/legal/privacy/" className="text-blue-600 hover:underline">PubMatic</a>
            </td>
            <td className="px-6 py-4  ">Registers a unique ID that identifies the user’s device during return visits across websites that use the same ad network. The ID is used to allow targeted ads.</td>
            <td className="px-6 py-4  ">3 months</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">PugT</td>
            <td className="px-6 py-4  ">
              <a href="https://pubmatic.com/legal/privacy/" className="text-blue-600 hover:underline">PubMatic</a>
            </td>
            <td className="px-6 py-4  ">Used to determine the number of times the cookies have been updated in the visitor’s browser. Used to optimize the website’s server efficiency.</td>
            <td className="px-6 py-4  ">30 days</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">_fbp</td>
            <td className="px-6 py-4  ">
              <a href="https://www.facebook.com/policy.php/" className="text-blue-600 hover:underline">Meta Platforms, Inc.</a>
            </td>
            <td className="px-6 py-4  ">Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.</td>
            <td className="px-6 py-4  ">3 months</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">_gcl_au</td>
            <td className="px-6 py-4  ">
              <a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">Google</a>
            </td>
            <td className="px-6 py-4  ">Used by Google AdSense for experimenting with advertisement efficiency across websites using their services.</td>
            <td className="px-6 py-4  ">3 months</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>

          <tr>
            <td className="px-6 py-4  ">aw_source</td>
            <td className="px-6 py-4  ">mdm.prabhaktech.com</td>
            <td className="px-6 py-4  ">Collects information about the users and their activity on the website. The information is used to track and analyze the user behaviour and to deliver product recommendations, product display and product features for optimized user experience.</td>
            <td className="px-6 py-4  ">30 days</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">cto_bundle</td>
            <td className="px-6 py-4  ">
              <a href="https://www.criteo.com/privacy/corporate-privacy-policy/" className="text-blue-600 hover:underline">Criteo</a>
            </td>
            <td className="px-6 py-4  ">Presents the user with relevant content and advertisement. The service is provided by third-party advertisement hubs, which facilitate real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">13 months</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">IR_8259</td>
            <td className="px-6 py-4  ">d.impactradius-event.com</td>
            <td className="px-6 py-4  ">Registers a unique ID that identifies the user’s device during return visits across websites that use the same ad network. The ID is used to allow targeted ads.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">IR_gbd</td>
            <td className="px-6 py-4  ">d.impactradius-event.com</td>
            <td className="px-6 py-4  ">Registers a unique ID that identifies the user’s device during return visits across websites that use the same ad network. The ID is used to allow targeted ads.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">um</td>
            <td className="px-6 py-4  ">
              <a href="https://www.teads.com/privacy-policy/" className="text-blue-600 hover:underline">Teads</a>
            </td>
            <td className="px-6 py-4  ">Used to identify the visitor across visits and devices. This allows the website to present the visitor with relevant advertisement – The service is provided by third party advertisement hubs, which facilitate real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">Pixel</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">tv_UICR</td>
            <td className="px-6 py-4  ">
              <a href="https://www.magnite.com/legal/advertising-technology-privacy-policy/" className="text-blue-600 hover:underline">Telaria</a>
            </td>
            <td className="px-6 py-4  ">Collects information on user behaviour on multiple websites. This information is used in order to optimize the relevance of advertisement on the website.</td>
            <td className="px-6 py-4  ">30 days</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">tvid</td>
            <td className="px-6 py-4  ">
              <a href="https://www.magnite.com/legal/advertising-technology-privacy-policy/" className="text-blue-600 hover:underline">Telaria</a>
            </td>
            <td className="px-6 py-4  ">Presents the user with relevant content and advertisement. The service is provided by third-party advertisement hubs, which facilitate real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">1 year</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>

          <tr>
            <td className="px-6 py-4  ">i/jot/embeds</td>
            <td className="px-6 py-4  ">
              <a href="https://twitter.com/en/privacy" className="text-blue-600 hover:underline">Twitter Inc.</a>
            </td>
            <td className="px-6 py-4  ">Sets a unique ID for the visitor, that allows third party advertisers to target the visitor with relevant advertisement. This pairing service is provided by third party advertisement hubs, which facilitates real-time bidding for advertisers.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">Pixel</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">RichHistory</td>
            <td className="px-6 py-4  ">
              <a href="https://twitter.com/en/privacy" className="text-blue-600 hover:underline">Twitter Inc.</a>
            </td>
            <td className="px-6 py-4  ">Collects data on visitors’ preferences and behaviour on the website – This information is used to make content and advertisement more relevant to the specific visitor.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">HTML</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">#-#</td>
            <td className="px-6 py-4  ">
              <a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">YouTube</a>
            </td>
            <td className="px-6 py-4  ">Pending</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">HTML</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">iU5q-!O9@$</td>
            <td className="px-6 py-4  ">
              <a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">YouTube</a>
            </td>
            <td className="px-6 py-4  ">Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">HTML</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">LAST_RESULT_ENTRY_KEY</td>
            <td className="px-6 py-4  ">
              <a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">YouTube</a>
            </td>
            <td className="px-6 py-4  ">Used to track user’s interaction with embedded content.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">LogsDatabaseV2:V#||LogsRequestsStore</td>
            <td className="px-6 py-4  ">
              <a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">YouTube</a>
            </td>
            <td className="px-6 py-4  ">Pending</td>
            <td className="px-6 py-4  ">Persistent</td>
            <td className="px-6 py-4  ">IDB</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">nextId</td>
            <td className="px-6 py-4  ">
              <a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">YouTube</a>
            </td>
            <td className="px-6 py-4  ">Used to track user’s interaction with embedded content.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">remote_sid</td>
            <td className="px-6 py-4  ">
              <a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">YouTube</a>
            </td>
            <td className="px-6 py-4  ">Necessary for the implementation and functionality of YouTube video-content on the website.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
            <td className="px-6 py-4  ">requests</td>
            <td className="px-6 py-4  ">
              <a href="https://policies.google.com/privacy" className="text-blue-600 hover:underline">YouTube</a>
            </td>
            <td className="px-6 py-4  ">Used to track user’s interaction with embedded content.</td>
            <td className="px-6 py-4  ">Session</td>
            <td className="px-6 py-4  ">HTTP</td>
          </tr>
          <tr>
      <td class="px-6 py-4   text-sm text-gray-700">ServiceWorkerLogsDatabase#SWHealthLog</td>
      <td class="px-6 py-4   text-sm text-gray-700">
        <a href="https://policies.google.com/privacy" class="text-gray-700 hover:text-blue-600">YouTube</a>
      </td>
      <td class="px-6 py-4   text-sm text-gray-700">Necessary for the implementation and functionality of YouTube video-content on the website.</td>
      <td class="px-6 py-4   text-sm text-gray-700">Persistent</td>
      <td class="px-6 py-4   text-sm text-gray-700">IDB</td>
    </tr>
    <tr>
      <td class="px-6 py-4   text-sm text-gray-700">TESTCOOKIESENABLED</td>
      <td class="px-6 py-4   text-sm text-gray-700">
        <a href="https://policies.google.com/privacy" class="text-gray-700 hover:text-blue-600">YouTube</a>
      </td>
      <td class="px-6 py-4   text-sm text-gray-700">Used to track user’s interaction with embedded content.</td>
      <td class="px-6 py-4   text-sm text-gray-700">1 day</td>
      <td class="px-6 py-4   text-sm text-gray-700">HTTP</td>
    </tr>
    <tr>
      <td class="px-6 py-4   text-sm text-gray-700">VISITOR_INFO1_LIVE</td>
      <td class="px-6 py-4   text-sm text-gray-700">
        <a href="https://policies.google.com/privacy" class="text-gray-700 hover:text-blue-600">YouTube</a>
      </td>
      <td class="px-6 py-4   text-sm text-gray-700">Tries to estimate the users’ bandwidth on pages with integrated YouTube videos.</td>
      <td class="px-6 py-4   text-sm text-gray-700">180 days</td>
      <td class="px-6 py-4   text-sm text-gray-700">HTTP</td>
    </tr>
    <tr>
      <td class="px-6 py-4   text-sm text-gray-700">VISITOR_PRIVACY_METADATA</td>
      <td class="px-6 py-4   text-sm text-gray-700">
        <a href="https://policies.google.com/privacy" class="text-gray-700 hover:text-blue-600">YouTube</a>
      </td>
      <td class="px-6 py-4   text-sm text-gray-700">Stores the user’s cookie consent state for the current domain</td>
      <td class="px-6 py-4   text-sm text-gray-700">180 days</td>
      <td class="px-6 py-4   text-sm text-gray-700">HTTP</td>
    </tr>
    <tr>
      <td class="px-6 py-4   text-sm text-gray-700">YSC</td>
      <td class="px-6 py-4   text-sm text-gray-700">
        <a href="https://policies.google.com/privacy" class="text-gray-700 hover:text-blue-600">YouTube</a>
      </td>
      <td class="px-6 py-4   text-sm text-gray-700">Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</td>
      <td class="px-6 py-4   text-sm text-gray-700">Session</td>
      <td class="px-6 py-4   text-sm text-gray-700">HTTP</td>
    </tr>
    <tr>
      <td class="px-6 py-4   text-sm text-gray-700">yt.innertube::nextId</td>
      <td class="px-6 py-4   text-sm text-gray-700">
        <a href="https://policies.google.com/privacy" class="text-gray-700 hover:text-blue-600">YouTube</a>
      </td>
      <td class="px-6 py-4   text-sm text-gray-700">Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</td>
      <td class="px-6 py-4   text-sm text-gray-700">Persistent</td>
      <td class="px-6 py-4   text-sm text-gray-700">HTML</td>
    </tr>
    <tr>
      <td class="px-6 py-4   text-sm text-gray-700">yt.innertube::requests</td>
      <td class="px-6 py-4   text-sm text-gray-700">
        <a href="https://policies.google.com/privacy" class="text-gray-700 hover:text-blue-600">YouTube</a>
      </td>
      <td class="px-6 py-4   text-sm text-gray-700">Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</td>
      <td class="px-6 py-4   text-sm text-gray-700">Persistent</td>
      <td class="px-6 py-4   text-sm text-gray-700">HTML</td>
    </tr>
    <tr>
      <td class="px-6 py-4   text-sm text-gray-700">ytidb::LAST_RESULT_ENTRY_KEY</td>
      <td class="px-6 py-4   text-sm text-gray-700">
        <a href="https://policies.google.com/privacy" class="text-gray-700 hover:text-blue-600">YouTube</a>
      </td>
      <td class="px-6 py-4   text-sm text-gray-700">Stores the user’s video player preferences using embedded YouTube video</td>
      <td class="px-6 py-4   text-sm text-gray-700">Persistent</td>
      <td class="px-6 py-4   text-sm text-gray-700">HTML</td>
    </tr>
    <tr>
      <td class="px-6 py-4   text-sm text-gray-700">YtIdbMeta#databases</td>
      <td class="px-6 py-4   text-sm text-gray-700">
        <a href="https://policies.google.com/privacy" class="text-gray-700 hover:text-blue-600">YouTube</a>
      </td>
      <td class="px-6 py-4   text-sm text-gray-700">Used to track user’s interaction with embedded content.</td>
      <td class="px-6 py-4   text-sm text-gray-700">Persistent</td>
      <td class="px-6 py-4   text-sm text-gray-700">IDB</td>
    </tr>
    <tr>
      <td class="px-6 py-4   text-sm text-gray-700">yt-remote-cast-available</td>
      <td class="px-6 py-4   text-sm text-gray-700">
        <a href="https://policies.google.com/privacy" class="text-gray-700 hover:text-blue-600">YouTube</a>
      </td>
      <td class="px-6 py-4   text-sm text-gray-700">Stores the user’s video player preferences using embedded YouTube video</td>
      <td class="px-6 py-4   text-sm text-gray-700">Session</td>
      <td class="px-6 py-4   text-sm text-gray-700">HTML</td>
    </tr>
    <tr class="h-12">
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">yt-remote-cast-installed</span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><a href="https://policies.google.com/privacy" class="text-gray-800"><span class="font-normal">YouTube</span></a></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Stores the user’s video player preferences using embedded YouTube video</span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Session</span></td>
            <td class="   px-4 py-2 text-gray-800"><span class="font-normal">HTML</span></td>
        </tr>
        <tr class="h-12">
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">yt-remote-connected-devices</span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><a href="https://policies.google.com/privacy" class="text-gray-800"><span class="font-normal">YouTube</span></a></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Stores the user’s video player preferences using embedded YouTube video</span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Persistent</span></td>
            <td class="   px-4 py-2 text-gray-800"><span class="font-normal">HTML</span></td>
        </tr>
        <tr class="h-12">
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">yt-remote-device-id</span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><a href="https://policies.google.com/privacy" class="text-gray-800"><span class="font-normal">YouTube</span></a></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Stores the user’s video player preferences using embedded YouTube video</span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Persistent</span></td>
            <td class="   px-4 py-2 text-gray-800"><span class="font-normal">HTML</span></td>
        </tr>
        <tr class="h-12">
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">yt-remote-fast-check-period</span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><a href="https://policies.google.com/privacy" class="text-gray-800"><span class="font-normal">YouTube</span></a></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Stores the user’s video player preferences using embedded YouTube video</span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Session</span></td>
            <td class="   px-4 py-2 text-gray-800"><span class="font-normal">HTML</span></td>
        </tr>
        <tr class="h-12">
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">yt-remote-session-app</span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><a href="https://policies.google.com/privacy" class="text-gray-800"><span class="font-normal">YouTube</span></a></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Stores the user’s video player preferences using embedded YouTube video</span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Session</span></td>
            <td class="   px-4 py-2 text-gray-800"><span class="font-normal">HTML</span></td>
        </tr>
        <tr class="h-12">
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">yt-remote-session-name</span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><a href="https://policies.google.com/privacy" class="text-gray-800"><span class="font-normal">YouTube</span></a></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Stores the user’s video player preferences using embedded YouTube video</span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Session</span></td>
            <td class=" px-4 py-2 text-gray-800"><span class="font-normal">HTML</span></td>
        </tr>
        <tr class="h-12">
            <td class="w-1/4 px-4 py-2 text-gray-800"><span>dap-uuid [x2]</span><span class="font-normal"><br/></span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal"><a href="https://mdm.prabhaktech.com" class="text-gray-800">mdm.prabhaktech.com</a></span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Anonymously identifies the visitor’s device to collect usage stats</span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Persistent</span></td>
            <td class=" px-4 py-2 text-gray-800"><span class="font-normal">HTML</span></td>
        </tr>
        <tr class="h-24">
            <td class="w-1/4 px-4 py-2 text-gray-800"><span>li_adsId</span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal"><a href="https://www.linkedin.com/legal/privacy-policy" class="text-gray-800">LinkedIn</a></span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Helps identify and track users for advertising purposes, enabling LinkedIn and advertising partners to offer more personalized and relevant advertising. The duration of the cookie was decided by LinkedIn.</span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Persistent</span></td>
            <td class=" px-4 py-2 text-gray-800"><span class="font-normal">HTML</span></td>
        </tr>
        <tr class="h-12">
            <td class="w-1/4 px-4 py-2 text-gray-800"><span>com.grafana.faro.lastNavigationId</span><span><br/></span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal"><a href="http://unpkg.com" class="text-gray-800">unpkg.com</a></span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Anonymously retrieves performance metrics for the monitoring of the user experience</span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Session</span></td>
            <td class=" px-4 py-2 text-gray-800"><span class="font-normal">HTML</span></td>
        </tr>
        <tr class="h-12">
            <td class="w-1/4 px-4 py-2 text-gray-800"><span>com.grafana.faro.session</span><span><br/></span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal"><a href="http://unpkg.com" class="text-gray-800">unpkg.com</a></span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Anonymously retrieves performance metrics for the monitoring of the user experience</span></td>
            <td class="w-1/4 px-4 py-2 text-gray-800"><span class="font-normal">Session</span></td>
            <td class=" px-4 py-2 text-gray-800"><span class="font-normal">HTML</span></td>
        </tr>

        </tbody>
      </table>

        </div>
    </SectionContainer>
  )
}

export default CookiePolicy