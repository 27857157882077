import React, { useEffect} from 'react'
import { SectionContainer } from '../components/common/SectionContainer'

function WebsitePolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <SectionContainer >
        <div className=" py-10 md:py-16">
            <h1 className="text-2xl font-bold text-gray-800">Safekidly Privacy Policy</h1>
            <p className="mt-4 text-gray-600">
            Safekidly Technologies S.L. (the “Company” or “We/us”) is committed to respecting and protecting the privacy of all visitors of our Website (www.mdm.prabhaktech.com). This Privacy Policy explains our practices regarding the use of personal data collected and processed through our Website or otherwise provided to us by email or via other websites that link to us. Please note that this privacy policy doesn’t regulate the processing of personal data collected through our services; if you want to know more about how we process our users’ data, please read the legal terms of our services.
            </p>
            <p className="mt-4 text-gray-600">
                The data controller of the data you provide us with is Safekidly Technologies SL, with domicile at 1st floor here showroom building, Raithupeta, Nandigama, NTR District, Andhra Pradesh, 521185.
            </p>
            <p className="mt-4 text-gray-600">
                You can contact the Data Protection Officer to send any suggestions, queries, doubts or complaints regarding data protection by writing to:&nbsp;
                <a href="mailto:info@prabhaktech.com" className="text-blue-500 hover:underline inline ">info@prabhaktech.com</a>
            </p>
            
            <p className="mt-4 font-bold text-gray-800">1. User acceptance of this Privacy Policy</p>
            <p className="mt-2 text-gray-600">
                By accessing or using our Website, and in particular by providing your personal information to us, or contacting us for any reason, you are required to explicitly accept this Privacy Policy and provide us with full consent, when needed, to the collection, storage and processing of personal data for which you are responsible in accordance with the terms set out in this policy, including the sending of electronic communications, as the case may be.
            </p>

            <p className="mt-4 font-bold text-gray-800">2. Personal data collection</p>
            <p className="mt-2 text-gray-600">
                <strong>Contact data:</strong> If you send us any email with personal data about you or a third party, we will process the personal data you send us.
            </p>
            <p className="mt-2 text-gray-600">
                <strong>Navigation data:</strong> Due to internet communication standards, when you visit our Website we automatically receive the URL of the site you came from and the site you visit when you leave our website. We also receive the Internet Protocol ("IP") address of your computer and the type of browser you are using. We use this information to analyze overall trends to improve the service. This information is not shared with third parties without your consent. Other than the above, and as stated in our&nbsp;
                <a href="/cookie-policy" className="text-blue-500 hover:underline inline">cookie policy</a>, we do not collect any personal data if you are only browsing the website.
            </p>

            <p className="mt-4 font-bold text-gray-800">3. Legal basis</p>
            <ul className="list-disc list-inside text-gray-600">
                <li>Our legitimate interest to manage the services and carry out the actions that you are requesting us. We consider any possible impact (both positive and negative) on you and on your rights before processing your data for our legitimate interest.</li>
                <li>In specific circumstances, to comply with a legal obligation we are subject to.</li>
            </ul>

            <p className="mt-4 font-bold text-gray-800">4. Use of personal data</p>
            <ul className="list-disc list-inside text-gray-600">
                <li>Contact you and personalize your experience</li>
                <li>Managing the service or action you are requesting us</li>
                <li>Improve our website and services</li>
            </ul>

            <p className="mt-4 font-bold text-gray-800">5. Data disclosure</p>
            <p className="mt-2 text-gray-600">
                We treat your personal data with strict confidentiality in accordance with applicable law. Unless otherwise indicated, your personal data will not be provided to third parties. We do not sell or assign to third parties lists with personal data, nor of any other type.
            </p>
            <p className="mt-2 text-gray-600">
                Nonetheless, we may disclose data as follows:
            </p>
            <ul className="list-disc list-inside text-gray-600">
                <li>We can make your personal data available to any company interested in buying, or actually buying, the Company or a part of its business and, consequently, give access to any national or international auditors to carry out the so-called "due diligence".</li>
                <li>We can share your personal data with other companies of our group of companies.</li>
                <li>We may give access to your personal data to our service providers if we reasonably consider such access and processing necessary to the performance of our services. We require that all third parties respect the security of your personal data and process them in accordance with the law. We do not allow our external service providers to use your personal data for their own purposes, and we only allow them to process your personal data for specific purposes and in accordance with our instructions.</li>
            </ul>

            <p className="mt-4 font-bold text-gray-800">6. International transfers</p>
            <p className="mt-2 text-gray-600">
                We use third-party technological services for the provision of services and Website, whose providers may process your data collected in the course of providing us their services indicated below, as sub-processors. These entities may be in jurisdictions that generally don’t provide adequate safeguards in relation to the processing of personal data. For all entities outside the Indian Area, we have entered into contracts with such entities that do include such safeguards, including the EC model clauses.
            </p>

            <p className="mt-4 font-bold text-gray-800">7. Security Measures</p>
            <p className="mt-2 text-gray-600">
                We implement security measures and personal data protection schemes as required by law to maintain the confidentiality and integrity of your data and protection against unauthorized access, modification, or destruction.
            </p>

            <p className="mt-4 font-bold text-gray-800">8. Data Retention</p>
            <p className="mt-2 text-gray-600">
                We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements, or until the deactivation of your account either by you or by us. After that period, the data will be retained (in a blocked and secure manner) for the required period of legal and administrative liability and to comply with legal obligations and other purposes set out above, which may be up to 10 years according to current applicable law.
            </p>

            <p className="mt-4 font-bold text-gray-800">9. User Rights</p>
            <p className="mt-2 text-gray-600">
                You are informed you may exercise at any time right to the portability of your data sent to us, to access, rectify, erase, block and oppose any processing of your personal data by sending an email request to&nbsp;
                <a href="mailto:info@prabhaktech.com" className="text-blue-500 hover:underline inline ">info@prabhaktech.com</a>&nbsp;or by writing to Safekidly Technologies SL, with domicile at 1st floor here showroom building, Raithupeta, Nandigama, NTR District, Andhra Pradesh Inida . You can also withdraw your consent to our processing at any time when we use your content as legal basis for processing your data. Withdrawal will not have retroactive effects but may prevent providing certain services.
            </p>
          
            <p className="mt-4 font-bold text-gray-800">10. General</p>
            <p className="mt-2 text-gray-600">
                We reserve the right to amend the terms of this Privacy Policy and will notify you by providing a clear notice of these changes by email or on our website, and in this Privacy Policy. If you continue to use and/or access our Website after such update, you will be deemed to accept the new terms. If you do not accept the update, please notify us and we will terminate any account you may have with us, and remove any of your personal data (except as required to be maintained for legal purposes), and you will not be able to continue to use services to which you have subscribed.
            </p>
            <p className="mt-2 text-gray-600">
                Unless a specific local regulation of mandatory application provides otherwise, the Privacy Policy is governed by the laws of Inida.
            </p>
        </div>
    </SectionContainer>
  )
}

export default WebsitePolicy